import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { DashboardNavbar, Footer, Sidenav } from "../layout";
import { Decrypt } from "../../../components/utils/decrypt";
import AdminNavConfig from "./side-nav-admin-config";
import CustomerNavConfig from "./side-nav-customer-config";
import { useMaterialTailwindController } from "../../../context";
import AppLogout from "../../../components/SessionOut/AppLogout";

const MainLayout = ({ children }) => {
  const [controller, dispatch] = useMaterialTailwindController();
  const { sidenavType } = controller;
  const { userRole } = Decrypt();

  return (
    <AppLogout>
      <>
        <div className='min-h-screen bg-blue-gray-50/50'>
          <Sidenav routes={userRole === "super_admin" ? AdminNavConfig : CustomerNavConfig} brandImg={sidenavType === "dark" ? "/img/logo-ct.png" : "/img/logo-ct-dark.png"} />
          <div className='p-4 xl:ml-80'>
            <DashboardNavbar />
            <Box>{children}</Box>
          </div>
        </div>
      </>
    </AppLogout>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
};
export default MainLayout;
