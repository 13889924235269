import React from "react";
import ReactDOM from "react-dom/client";
import App from "~/App.jsx";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "~/redux/store.js";
import { ThemeProvider } from "@material-tailwind/react";
import { ReactQueryProvider } from "~/provider/QueryProvider";
import { MaterialTailwindControllerProvider } from "./context";
import "../src/assets/css/tailwind.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        {/* <ReactQueryProvider> */}
        <ThemeProvider>
          <MaterialTailwindControllerProvider>
            <App />
          </MaterialTailwindControllerProvider>
        </ThemeProvider>
        {/* </ReactQueryProvider> */}
      </Provider>
    </HashRouter>
  </React.StrictMode>
);
