import {
  HomeIcon,
  UserCircleIcon,
  NewspaperIcon,
  ComputerDesktopIcon,
  BeakerIcon,
  TicketIcon,
  RocketLaunchIcon,
  ChatBubbleBottomCenterIcon,
  UserGroupIcon,
  ViewColumnsIcon,
  AcademicCapIcon,
  FaceSmileIcon,
  SpeakerWaveIcon,
  QuestionMarkCircleIcon,
  PhotoIcon,
  AdjustmentsHorizontalIcon,
  BriefcaseIcon,
  CircleStackIcon,
  ShieldCheckIcon,
  PhoneArrowDownLeftIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { Home } from "../../dashboard";
import CustomerList from "../../admin-modules/customers";
import LicenseList from "../../admin-modules/licenses";
import HostsList from "../../admin-customer-modules/hosts";
import SolutionsList from "../../admin-customer-modules/solutions";
import TicketsList from "../../admin-customer-modules/tickets";
import SolutionsRunList from "../../admin-customer-modules/solutions-run";
import FeedBacksList from "../../admin-customer-modules/feedbacks";
import SentimentalList from "../../admin-customer-modules/emotional-status";
import BlogsList from "../../admin-modules/blogs";
import CaseStudiesList from "../../admin-modules/case-studies";
import TestimonialsList from "../../admin-modules/testimonials";
import AnnouncementsList from "../../admin-modules/announcement";
import FAQsList from "../../admin-modules/FAQs";
import BannersList from "../../admin-modules/banners";
import OurSolutionsList from "../../admin-modules/our-solutions";
import CareersList from "../../admin-modules/careers";
import ContentsofZeroFaiList from "../../admin-modules/Content-ZeroFAI";
import ComplianceHardeningList from "../../admin-customer-modules/compliance-hardning";
import Profile from "../../authentication/profile";
import ChangePassword from "../../authentication/change-password";
import ContactUsList from "../../admin-modules/contactUs";
import CareerListVacancies from "../../admin-modules/careers/recivedApplicationsList";
import DetailedSentimentalStatus from "../../admin-customer-modules/emotional-status/detailedList";
import DetailedComplianceHardning from "../../admin-customer-modules/compliance-hardning/detailedList";
import Reports from "../../admin-customer-modules/reports";
const icon = {
  className: "w-5 h-5 text-inherit",
};

const AdminNavConfig = [
  {
    layout: "dashboard",
    pages: [
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/detailed-hardenning-list",
        element: <DetailedComplianceHardning />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/emotion-detailed-list",
        element: <DetailedSentimentalStatus />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/carer-applicationRecivedList",
        element: <CareerListVacancies />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/profile",
        element: <Profile />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/home",
        element: <Home />,
      },
      {
        icon: <UserCircleIcon {...icon} />,
        name: "customer",
        path: "/customer",
        element: <CustomerList />,
      },
      {
        icon: <NewspaperIcon {...icon} />,
        name: "license",
        path: "/license",
        element: <LicenseList />,
      },
      {
        icon: <ViewColumnsIcon {...icon} />,
        name: "blogs",
        path: "/blogs",
        element: <BlogsList />,
      },
      {
        icon: <AcademicCapIcon {...icon} />,
        name: "case studies",
        path: "/case-studies",
        element: <CaseStudiesList />,
      },
      {
        icon: <FaceSmileIcon {...icon} />,
        name: "testimonials",
        path: "/testimonials",
        element: <TestimonialsList />,
      },
      {
        icon: <SpeakerWaveIcon {...icon} />,
        name: "announcement",
        path: "/announcement",
        element: <AnnouncementsList />,
      },
      {
        icon: <QuestionMarkCircleIcon {...icon} />,
        name: "FAQs",
        path: "/faq",
        element: <FAQsList />,
      },
      {
        icon: <PhotoIcon {...icon} />,
        name: "banners",
        path: "/banners",
        element: <BannersList />,
      },

      {
        icon: <AdjustmentsHorizontalIcon {...icon} />,
        name: "our solutions",
        path: "/our-solutions",
        element: <OurSolutionsList />,
      },
      {
        icon: <BriefcaseIcon {...icon} />,
        name: "careers",
        path: "/careers",
        element: <CareersList />,
      },
      {
        icon: <CircleStackIcon {...icon} />,
        name: "content zeroFAI",
        path: "/content-zeroFAI",
        element: <ContentsofZeroFaiList />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: "hosts",
        path: "/hosts",
        element: <HostsList />,
      },
      {
        icon: <BeakerIcon {...icon} />,
        name: "solutions",
        path: "/solutions",
        element: <SolutionsList />,
      },
      {
        icon: <TicketIcon {...icon} />,
        name: "tickets",
        path: "/tickets",
        element: <TicketsList />,
      },
      {
        icon: <RocketLaunchIcon {...icon} />,
        name: "solution run",
        path: "/solution-run",
        element: <SolutionsRunList />,
      },
      {
        icon: <ChatBubbleBottomCenterIcon {...icon} />,
        name: "feedbacks",
        path: "/feedbacks",
        element: <FeedBacksList />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: "Emotions  Status",
        path: "/emotional-status",
        element: <SentimentalList />,
      },
      {
        icon: <PhoneArrowDownLeftIcon {...icon} />,
        name: "Contact Us",
        path: "/contact-us",
        element: <ContactUsList />,
      },
      {
        icon: <ShieldCheckIcon {...icon} />,
        name: "compliance hardening",
        path: "/compliance-hardening",
        element: <ComplianceHardeningList />,
      },
      {
        icon: <DocumentTextIcon {...icon} />,
        name: "reports",
        path: "/reports",
        element: <Reports />,
      },
    ],
  },
];

export default AdminNavConfig;
