/* eslint-disable no-useless-catch */
import axios from "axios";
import { Decrypt } from "../../components/utils/decrypt";

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    // console.log("Interceptor executing");
    try {
      const { accessToken } = await Decrypt(); // Wait for the accessToken
      // console.log(accessToken);
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        // console.log("Token added to headers:", accessToken);
      }
    } catch (error) {
      console.error("Interceptor error:", error);
    }
    return config;
  },
  (error) => {
    console.error("Interceptor error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { refreshToken } = await Decrypt(); // Wait for the accessToken

        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}auth/api/v1/token/refresh/`, { refresh: refreshToken });
        const { access } = response.data;

        localStorage.setItem("accessToken", access);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access}`;
        return axios(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
