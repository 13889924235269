import * as Yup from "yup";

export const customerCreationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required!"),
  lastName: Yup.string().required("Last Name is required!"),
  email: Yup.string().email("please enter valid email!").required("Email is required!"),
  phoneNo: Yup.string().min(10, "Min 10 Digit Required!").required("Phone No is required!"),
});

export const customerInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
};
