import React, { useRef } from "react";
import useResponsive from "../../../components/hooks/useResponsive";
import { Button, Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { reportsInitialValues, reportsSchema } from "./formHelper";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { downloadReports } from "../../../redux/slices/adminSlice";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import moment from "moment";
import { Decrypt } from "../../../components/utils/decrypt";

const Reports = () => {
  const isDesktop = useResponsive("up", "lg");
  const formRef = useRef();
  const dispatch = useDispatch();
  const { userRole } = Decrypt();

  const selectOption = [
    {
      key: 1,
      value: "Solution Run",
    },
    {
      key: 2,
      value: "Feedback",
    },

    {
      key: 3,
      value: "All hosts",
    },
    {
      key: 4,
      value: "All Solutions",
    },

    {
      key: 6,
      value: "Complaince: For all hosts for all data",
    },
    {
      key: 7,
      value: "Emotions: For all hosts for all data",
    },
    // {
    //   key: 6,
    //   value: "Emotions: For single host for single date",
    // },
    // {
    //   key: 4,
    //   value: "Complaince: For single host for single date",
    // },
  ];
  if (userRole === "super_admin")
    selectOption.push({
      key: 5,
      value: "All Customers",
    });

  const handleExportReports = () => {
    const { values, resetForm, setSubmitting } = formRef.current;
    let type = "";

    if (values.selectOption === 1) {
      type = "solution_run";
    }
    if (values.selectOption === 2) {
      type = "feedback";
    }
    if (values.selectOption === 3) {
      type = "hosts";
    }
    if (values.selectOption === 4) {
      type = "solutions";
    }
    if (values.selectOption === 5) {
      type = "customers";
    }
    if (values.selectOption === 6) {
      type = "complaince_all_host";
    }
    if (values.selectOption === 7) {
      type = "emotions_all_host";
    }
    // console.log("type==>", type);
    const payload = {
      type: type,
      start_date: values.startDate ? moment(values.startDate).format("DD-MM-YYYY") : "",
      end_date: values.endDate ? moment(values.endDate).format("DD-MM-YYYY") : "",
    };
    // console.log(payload);
    dispatch(enableLoading(true));
    dispatch(downloadReports(payload))
      .unwrap()
      .then(({ reportsData }) => {
        // console.log("reportsData", reportsData);
        const file = new Blob([reportsData], {
          type: "data:text/csv;charset=utf-8",
        });
        const objUrl = window.URL.createObjectURL(file);
        const anchorElement = document.createElement("a");
        anchorElement.href = objUrl;
        anchorElement.download = `${type}_report_${moment().format("DD-MM_YYYY")}.csv`;
        anchorElement.click();
        anchorElement.remove();
        window.URL.revokeObjectURL(objUrl);
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "success",
            msg: reportsData?.message ? reportsData?.message : "Downloaded Successfully!",
          })
        );
        resetForm();
        setSubmitting(false);
      })
      .catch(({ reportsData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: reportsData?.message ? reportsData?.message : "Something went wrong!",
          })
        );
        resetForm();
        setSubmitting(false);
      });
  };
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader variant='gradient' color='blue' className='mb-8 p-6'>
          <Typography variant='h6' color='white'>
            Reports Download
          </Typography>
        </CardHeader>
        <CardBody className='p-4'>
          <Formik initialValues={reportsInitialValues} validationSchema={reportsSchema} onSubmit={handleExportReports} validateOnChange innerRef={formRef} noValidate>
            {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={2} direction='row'>
                  <FormControl fullWidth variant='outlined' error={Boolean(touched.selectOption && errors.selectOption)}>
                    <InputLabel htmlFor='outlined-adornment'>Select Option</InputLabel>
                    <Select
                      id='outlined-adornment-issueCategory'
                      name='selectOption'
                      value={values.selectOption}
                      onChange={(e) => setFieldValue("selectOption", e.target.value)}
                      label='Select Option'
                      MenuProps={{
                        style: {
                          maxHeight: 300,
                        },
                        disableScrollLock: true,
                      }}
                    >
                      {selectOption?.map((option) => (
                        <MenuItem key={option.key} value={option.key} sx={{ fontSize: 16, fontWeight: 800 }}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.selectOption && errors.selectOption && (
                      <FormHelperText error id='standard-weight-helper-text-selectOption'>
                        {errors.selectOption}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {(values.selectOption == 1 || values.selectOption == 2) && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ width: 400 }}
                          label='From'
                          format='DD/MM/YYYY'
                          onChange={(e) => setFieldValue("startDate", e)}
                          value={values?.startDate}
                          name='startDate'
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              helperText: touched.startDate && errors.startDate,
                              error: Boolean(touched.startDate && errors.startDate),
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ width: 400 }}
                          label='To'
                          format='DD/MM/YYYY'
                          onChange={(e) => setFieldValue("endDate", e)}
                          value={values?.endDate}
                          name='endDate'
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              helperText: touched.endDate && errors.endDate,
                              error: Boolean(touched.endDate && errors.endDate),
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </>
                  )}
                  <div>
                    <Button className='mt-1' style={{ width: 150 }} type='submit' disableElevation disabled={isSubmitting} variant='outlined' onClick={() => handleSubmit} inpi>
                      Export Report
                    </Button>
                  </div>
                </Stack>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default Reports;
