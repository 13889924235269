import Iconify from "~/components/iconify";
import { InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import { setSnackBar } from "~/redux/slices/snackBarSlice";

import { enableLoading } from "../../../redux/slices/loadSlice";
import { changePasswordInitialValues } from "./formHelper";
import { changePasswordSchema } from "./formHelper";
import { changePassword } from "../../../redux/slices/adminSlice";

function ChangePassword() {
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleChangePassword = () => {
    const { values, setSubmitting, resetForm } = formRef.current;
    // console.log("values", values);
    dispatch(enableLoading(true));
    dispatch(changePassword({ old_password: values.currentPassword, new_password: values.newPassword }))
      .unwrap()
      .then(({ changedPasswordData }) => {
        // console.log(changedPasswordData);
        dispatch(enableLoading(false));
        setSubmitting(false);
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "success",
            msg: changedPasswordData?.message,
          })
        );
        navigate("/sign-in");
        window.sessionStorage.clear();
      })
      .catch(({ changedPasswordData }) => {
        setSubmitting(false);
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: changedPasswordData?.message,
          })
        );

        // resetForm();
      });
  };

  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url('../../assets/img/background-image.png')] bg-cover	bg-center">
        <div className='absolute inset-0 h-full w-full bg-gray-900/75' />
      </div>
      <Card className='mx-3 -mt-16 mb-6 lg:mx-4 border border-blue-gray-100 items-center'>
        <CardBody className='p-4'>
          <div className='mb-10 flex items-center justify-between flex-wrap gap-6'>
            <div className='flex  gap-2'>
              <Formik initialValues={changePasswordInitialValues} validationSchema={changePasswordSchema} innerRef={formRef} onSubmit={handleChangePassword}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isSubmitting, errors }) => (
                  <>
                    <div className='flex flex-col items-center'>
                      <Typography variant='h5' className='mb-6'>
                        Change Password
                      </Typography>
                      <form className='mt-6 mb-6 flex flex-col items-center' onSubmit={handleSubmit}>
                        <div className='mb-2 flex flex-col gap-6'>
                          <div className='flex flex-col'>
                            <Typography variant='small' color='blue-gray' className='font-medium'>
                              Current Password
                            </Typography>
                            <TextField
                              variant='outlined'
                              size='small'
                              placeholder='********'
                              name='currentPassword'
                              className='bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={showOldPassword ? "text" : "password"}
                              value={values.currentPassword}
                              error={touched.currentPassword && Boolean(errors.currentPassword)}
                              helperText={touched.currentPassword && errors.currentPassword}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment style={{ cursor: "pointer" }} position='end'>
                                    <Iconify
                                      onClick={() => setShowOldPassword(!showOldPassword)}
                                      icon={showOldPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className='flex flex-col'>
                            <Typography variant='small' color='blue-gray' className='font-medium'>
                              New Password
                            </Typography>
                            <TextField
                              variant='outlined'
                              size='small'
                              placeholder='********'
                              name='newPassword'
                              className='bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type={showNewPassword ? "text" : "password"}
                              value={values.newPassword}
                              error={touched.newPassword && Boolean(errors.newPassword)}
                              helperText={touched.newPassword && errors.newPassword}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment style={{ cursor: "pointer" }} position='end'>
                                    <Iconify
                                      onClick={() => setShowNewPassword(!showNewPassword)}
                                      icon={showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                        <Button type='submit' style={{ backgroundColor: "#1983c4", marginTop: "20px" }} disabled={isSubmitting}>
                          Submit
                        </Button>
                      </form>
                    </div>
                  </>
                )}
              </Formik>
              <div></div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ChangePassword;
