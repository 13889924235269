import React, { useState, useEffect } from "react";
import { Alert, Slide, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackBar } from "../../redux/slices/snackBarSlice";

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [snackbarType, setSnackBarType] = useState(1);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const snackBar = useSelector((state) => state?.snackBar);

  useEffect(() => {
    setSnackBarOpen(snackBar?.isOpen);
    setSnackBarType(snackBar?.severity);
    setSnackBarMessage(snackBar?.msg);
  }, [snackBar]);

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    dispatch(setSnackBar({ isOpen: false, severity: "", msg: "" }));
  };

  const [state, setState] = useState({
    vertical: "top",
    horizontal: "center",
    Transition: Slide,
  });
  const { vertical, horizontal } = state;
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        onClose={handleClose}
        sx={{
          width: "auto",
        }}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        TransitionComponent={state.Transition}
        autoHideDuration={window.navigator.onLine ? 5000 : null}
      >
        <Alert
          variant='filled'
          sx={{
            width: "100%",
            fontSize: "18px",
            "& .MuiAlert-icon": {
              padding: "8px 0",
            },
            "& .MuiAlert-action": {
              padding: "7px 0",
              paddingLeft: "10px",
            },
          }}
          onClose={handleClose}
          severity={snackbarType}
          color={snackbarType}
        >
          <b>{snackbarMessage}</b>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
