import * as Yup from "yup";

export const reportsInitialValues = {
  selectOption: "",
  startDate: null,
  endDate: null,
};
export const reportsSchema = Yup.object().shape({
  selectOption: Yup.string().required("This is a required field."),
  startDate: Yup.date().when("selectOption", {
    is: (selectOption) => selectOption == 1 || selectOption == 2,
    then: () => Yup.date().required("This is a required field."),
    otherwise: () => Yup.date().notRequired(),
  }),
  endDate: Yup.date().when("selectOption", {
    is: (selectOption) => selectOption == 1 || selectOption == 2,
    then: () => Yup.date().min(Yup.ref("startDate"), "To date has to be more than from date").required("This is a required field"),
    otherwise: () => Yup.date().notRequired(),
  }),
});
