import http from "./apiSevice";

class WebsiteServices {
  async GetCareerList() {
    const response = await http.get("website/api/v1/carrers/");
    return response;
  }
  async GetOurSolutionList() {
    const response = await http.get("website/api/v1/solutions/");
    return response;
  }
  async GetTestimonialList() {
    const response = await http.get("website/api/v1/testimonial/");
    return response;
  }
  async GetCaseStudyList() {
    const response = await http.get("website/api/v1/casestudy/");
    return response;
  }
  async GetBlogsList() {
    const response = await http.get("website/api/v1/blogs/");
    return response;
  }
  async SubmitContactUsForm(payload) {
    const response = await http.post(`website/api/v1/contact/us/`, payload);
    return response;
  }
  async SubmitApplicationForm(payload) {
    // console.log("payload", payload);
    const response = await http.post(`website/api/v1/carrers/`, payload);
    return response;
  }
}

export default new WebsiteServices();
