import http from "./apiSevice";

class ListServices {
  //admin-super-admin-list
  async GetCareerList(payload) {
    const response = await http.get("portal/api/v1/carrers/?page_size=10000");
    return response;
  }
  async GetCareerListVacancies({ id }) {
    const response = await http.get(`portal/api/v1/carrers/?carrer=${id}`);
    return response;
  }
  async GetSentimentalDetailedList({ id }) {
    const response = await http.get(`portal/api/v1/sentiment/?host_id=${id}`);
    return response;
  }
  async GetOurSolutionList(payload) {
    const response = await http.get("portal/api/v1/solutions/?page_size=10000");
    return response;
  }
  async GetTestimonialList(payload) {
    const response = await http.get("portal/api/v1/testimonial/?page_size=10000");
    return response;
  }
  async GetCaseStudyList(payload) {
    const response = await http.get("portal/api/v1/casestudy/?page_size=10000");
    return response;
  }
  async GetBlogsList(payload) {
    const response = await http.get("portal/api/v1/blogs/?page_size=10000");
    return response;
  }
  async GetCustomerList(payload) {
    const response = await http.get("portal/api/v1/customer/?page_size=10000");
    return response;
  }
  async GetLicenseList(payload) {
    const response = await http.get("portal/api/v1/license/?page_size=10000");
    return response;
  }
  async GetAnnouncementList(payload) {
    const response = await http.get("portal/api/v1/announcement/?page_size=10000");
    return response;
  }
  async GetContactUsList(payload) {
    const response = await http.get("portal/api/v1/contact/us/?page_size=10000");
    return response;
  }

  //admin-customer-list
  async GetHostList(payload) {
    const response = await http.get("portal/api/v1/host/?page_size=10000");
    return response;
  }
  async GetSolutionList(payload) {
    const response = await http.get("portal/api/v1/solutions/?page_size=10000");
    return response;
  }
  async GetSolutionRunList(payload) {
    const response = await http.get("portal/api/v1/solution/run/?page_size=10000");
    return response;
  }
  async GetTicketList(payload) {
    const response = await http.get("portal/api/v1/ticket/?page_size=10000");
    return response;
  }
  async GetFeedBackList(payload) {
    const response = await http.get("portal/api/v1/feedback/?page_size=10000");
    return response;
  }
  async GetSentimentList(payload) {
    const response = await http.get("portal/api/v1/sentiment/?page_size=10000");
    return response;
  }
  async GetComplainceList(payload) {
    const response = await http.get(`portal/api/v1/complaince/?page_size=10000`);
    return response;
  }
  async GetComplainceDetailedList({ id }) {
    const response = await http.get(`portal/api/v1/complaince/?host_id=${id}`);
    return response;
  }
}

export default new ListServices();
