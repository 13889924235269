import http from "./apiSevice";

class LandingPageService {
  //admin-list

  async GetOurSolutions(payload) {
    const response = await http.get("website/api/v1/solutions/");
    return response;
  }
  async GetCareer(payload) {
    const response = await http.get("website/api/v1/carrers/");
    return response;
  }
}

export default new LandingPageService();
