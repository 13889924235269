import { createSlice } from "@reduxjs/toolkit";

const initialState = { message: "", updatePayload: false };

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
    updateEffect: (state, action) => {
      return { updatePayload: action.payload };
    },
    setUserRole: (state, action) => {
      return { userRole: action.payload };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage, updateEffect, setUserRole } = actions;
export default reducer;
