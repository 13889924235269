/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
// prop type is a library for typecheking
import PropTypes from "prop-types";
import { Decrypt } from "../components/utils/decrypt";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../redux/slices/snackBarSlice";
import { enableLoading } from "../redux/slices/loadSlice";

const ProtectedRoute = ({ children, roles }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessToken, userRole } = Decrypt();
  const userHasRequiredRole = roles?.includes(userRole);

  useEffect(() => {
    if ((accessToken && !userHasRequiredRole) || !accessToken)
      // dispatch(
      //   setSnackBar({
      //     isOpen: true,
      //     severity: "error",
      //     msg: "Unauthorized Access",
      //   })
      // );
      dispatch(enableLoading(false));
  }, [accessToken, dispatch, userHasRequiredRole]);
  // console.log("userRole", userRole);
  // console.log("userHasRequiredRole", userHasRequiredRole);
  // console.log("accessToken ::: " + accessToken);
  if (!accessToken) {
    return <Navigate to='/sign-in' state={{ from: location }} replace />;
  }

  if (accessToken && !userHasRequiredRole) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }
  return children;
};

ProtectedRoute.proptypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.any,
};

export default ProtectedRoute;
