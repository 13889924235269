/* eslint-disable react/display-name */
import React, { Suspense } from "react";
import LoaderContainer from "./LoaderContainer";

// ================= components imports =======================

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderContainer />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
