import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Loadable from "../components/Loadable/Loadbale";
import { Dashboard } from "../components/layouts";
import ProtectedRoute from "./protectedRoute";
import PublicRoute from "./publicRoutes";
import MainLayout from "../pages/widgets/layout-config/mainlayout";
// import { Dashboard } from "../components/layouts";

// import PublicRoute from "./publicRoute";
// const MainLayout = Loadable(lazy(() => import("../components/mainlayout/index")));

//Detailed View Component
const CustomerDetailedView = Loadable(lazy(() => import("../pages/admin-modules/customers/detailedView")));
const FeedBacksDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/feedbacks/detailedView")));
const SolutionsDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/solutions/detailedView")));
const TicketDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/tickets/detailedView")));
const SolutionRunDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/solutions-run/detailedView")));
const HostsDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/hosts/detailedView")));
const SentimentsDetailedView = Loadable(lazy(() => import("../pages/admin-customer-modules/emotional-status/detailedView")));
const LicenseDetailedView = Loadable(lazy(() => import("../pages/admin-modules/licenses/detailedView")));
const BlogsDetailedView = Loadable(lazy(() => import("../pages/admin-modules/blogs/detailedView")));
const TestimonialDetailedView = Loadable(lazy(() => import("../pages/admin-modules/testimonials/detailedView")));
const CaseStudyDetailedView = Loadable(lazy(() => import("../pages/admin-modules/case-studies/detailedView")));
const CareerDetailedView = Loadable(lazy(() => import("../pages/admin-modules/careers/detailedView")));
const OurSolutionDetailedView = Loadable(lazy(() => import("../pages/admin-modules/our-solutions/detailedView")));
const AnnouncementDetailedView = Loadable(lazy(() => import("../pages/admin-modules/announcement/detailedView")));
const ContactUsDetailedView = Loadable(lazy(() => import("../pages/admin-modules/contactUs/detailedView")));
const WebsiteBlogsDetailedView = Loadable(lazy(() => import("../pages/LandingPage/DetailedBlogView")));

//Main Components
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const SignIn = Loadable(lazy(() => import("../pages/authentication/login/sign-in")));
const AllOurSolutions = Loadable(lazy(() => import("../pages/LandingPage/AllOurSolutions")));
const ForgetPassword = Loadable(lazy(() => import("../pages/authentication/forget-password/forget-password")));
const OTPVerification = Loadable(lazy(() => import("../pages/authentication/forget-password/verifyOtp")));
const Testimonials = Loadable(lazy(() => import("../pages/LandingPage/Testimonials")));
const CaseStudies = Loadable(lazy(() => import("../pages/LandingPage/CaseStudies")));
const FAQ = Loadable(lazy(() => import("../pages/LandingPage/FAQ")));
const Blogs = Loadable(lazy(() => import("../pages/LandingPage/Blogs")));
const Careers = Loadable(lazy(() => import("../pages/LandingPage/Career")));
const AboutUs = Loadable(lazy(() => import("../pages/LandingPage/AboutUsReadMore")));
const CareerDetails = Loadable(lazy(() => import("../pages/LandingPage/CareerDetails")));

const BaseRoutes = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route
        exact
        index
        element={
          <PublicRoute>
            <LandingPage />
          </PublicRoute>
        }
      />
      <Route
        exact
        path='/sign-in'
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      />
      <Route
        exact
        path='/forget-password'
        element={
          <PublicRoute>
            <ForgetPassword />
          </PublicRoute>
        }
      />
      <Route
        exact
        path='/verify-otp'
        element={
          <PublicRoute>
            <OTPVerification />
          </PublicRoute>
        }
      />
      <Route
        path='/our-solutions'
        element={
          <PublicRoute>
            <AllOurSolutions />
          </PublicRoute>
        }
      />
      <Route
        path='/testimonials'
        element={
          <PublicRoute>
            <Testimonials />
          </PublicRoute>
        }
      />
      <Route
        path='/case-studie'
        element={
          <PublicRoute>
            <CaseStudies />
          </PublicRoute>
        }
      />
      <Route
        path='/faq'
        element={
          <PublicRoute>
            <FAQ />
          </PublicRoute>
        }
      />
      <Route
        path='/blogs'
        element={
          <PublicRoute>
            <Blogs />
          </PublicRoute>
        }
      />
      <Route
        path='/careers'
        element={
          <PublicRoute>
            <Careers />
          </PublicRoute>
        }
      />
      <Route
        path='/about-us'
        element={
          <PublicRoute>
            <AboutUs />
          </PublicRoute>
        }
      />
      <Route
        path='/career-details'
        element={
          <PublicRoute>
            <CareerDetails />
          </PublicRoute>
        }
      />
      <Route
        path='/blogs/:id'
        element={
          <PublicRoute>
            <WebsiteBlogsDetailedView />
          </PublicRoute>
        }
      />

      {/* main layout */}
      <Route
        element={
          <MainLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </MainLayout>
          // <MainLayout />
        }
      >
        <Route
          path='/dashboard/customer-detailed-view'
          element={
            // <PublicRoute>
            <CustomerDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/feedbacks-detailed-view'
          element={
            // <PublicRoute>
            <FeedBacksDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/solutions-detailed-view'
          element={
            // <PublicRoute>
            <SolutionsDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/hosts-detailed-view'
          element={
            // <PublicRoute>
            <HostsDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/solution-run-detailed-view'
          element={
            // <PublicRoute>
            <SolutionRunDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/tickets-detailed-view'
          element={
            // <PublicRoute>
            <TicketDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/sentiment-detailed-view'
          element={
            // <PublicRoute>
            <SentimentsDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/license-detailed-view'
          element={
            // <PublicRoute>
            <LicenseDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/blogs-detailed-view'
          element={
            // <PublicRoute>
            <BlogsDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/testimonial-detailed-view'
          element={
            // <PublicRoute>
            <TestimonialDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/careers-detailed-view'
          element={
            // <PublicRoute>
            <CareerDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/case-study-detailed-view'
          element={
            // <PublicRoute>
            <CaseStudyDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/contact-us-detailed-view'
          element={
            // <PublicRoute>
            <ContactUsDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/our-solutions-detailed-view'
          element={
            // <PublicRoute>
            <OurSolutionDetailedView />
            // </PublicRoute>
          }
        />
        <Route
          path='/dashboard/announcement-detailed-view'
          element={
            // <PublicRoute>
            <AnnouncementDetailedView />
            // </PublicRoute>
          }
        />
      </Route>
      {/* main layout end */}
      <Route
        path='/dashboard/*'
        element={
          <ProtectedRoute roles={["super_admin", "customer"]}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path='*' element={<Navigate to='/dashboard/home' replace />} />
      {/* <Route path='404' element={<PageNotFound />} />
      <Route path='*' element={<Navigate to='/404' replace />} /> */}
    </Routes>
  );
};

export default BaseRoutes;
