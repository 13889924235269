import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Decrypt } from "../components/utils/decrypt";

function PublicRoute({ children }) {
  const location = useLocation();
  const { accessToken } = Decrypt();

  if (accessToken?.length > 0) {
    return <Navigate to='/dashboard/home' state={{ from: location }} replace />;
  }

  return children;
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
