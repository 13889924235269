import { configureStore } from "@reduxjs/toolkit";
import snackBarSlice from "./slices/snackBarSlice";
import loadReducer from "./slices/loadSlice";
import adminReducer from "./slices/adminSlice";
import landingReducer from "./slices/landingPageSlice";
import listReducer from "./slices/listSlice";
import websiteReducer from "./slices/websiteSlice";
import messageReducer from "./slices/message";

export const store = configureStore({
  reducer: {
    snackBar: snackBarSlice,
    load: loadReducer,
    admin: adminReducer,
    landing: landingReducer,
    list: listReducer,
    website: websiteReducer,
    message: messageReducer,
  },
});
