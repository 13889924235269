import { Card, CardHeader, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import { Divider, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify";

export function StatisticsCard({ cardIconColor, cardIcon, title, value, path }) {
  const navigate = useNavigate();

  return (
    <Card
      className='border border-blue-gray-100 shadow-sm'
      style={{ cursor: path ? "pointer" : "" }}
      onClick={() => {
        if (path) navigate(path, { state: { isDashboard: true } }); //will use isdasjboard true for backbutton on lists
      }}
    >
      <CardBody className='p-4 text-center' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant='h6' className='font-bold text-blue-gray-600' style={{ marginBottom: "auto" }}>
          {title}
        </Typography>
        <Divider orientation='horizontal' variant='middle' flexItem sx={{ margin: "6px" }} />
        {title === "System Health Report" ? (
          <>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h5'
              color='blue'
            >
              Overall Analysis : {value?.overall_analysis ? Math.round(value?.overall_analysis) : 0} %
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='green'
            >
              Healthy Assets : {value?.healthy_assets ? Math.round(value?.healthy_assets) : 0}
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='red'
            >
              Unhealthy Assets : {value?.unhealthy_assets ? Math.round(value?.unhealthy_assets) : 0}
            </Typography>
          </>
        ) : title.includes("Solution Run") ? (
          <>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='green'
            >
              Resolution(Autofix) : {value?.autofix ? value?.autofix : 0}
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='green'
            >
              Resolution(KB) : {value?.KB ? value?.KB : 0}
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='blue'
            >
              Tickets : {value?.tickets ? value?.tickets : 0}
            </Typography>
          </>
        ) : title === "Compliance Report" ? (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='blue'
            >
              Complaint : {value?.complaint}
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='blue'
            >
              Non Complaint : {value?.non_complaint}
            </Typography>
          </div>
        ) : title.includes("Licence") ? (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='red'
            >
              Used License : {value?.used_license}
            </Typography>
            <Typography
              // style={{ marginLeft: "auto" }}
              variant='h6'
              color='green'
            >
              Free License : {value?.free_license}
            </Typography>
          </div>
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", marginBottom: "1rem" }}>
              {cardIcon && <Iconify icon={cardIcon} color={cardIconColor} width='2rem' style={{ marginRight: "1rem" }} />}
              <Typography variant='h3' color='blue-gray'>
                {value}
              </Typography>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
}

StatisticsCard.defaultProps = {
  color: "blue",
  footer: null,
};

StatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  title: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  path: PropTypes.node,
  cardIcon: PropTypes.node,
  cardIconColor: PropTypes.node,
};

StatisticsCard.displayName = "/src/widgets/cards/statistics-card.jsx";

export default StatisticsCard;
