import * as Yup from "yup";

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("This is required feild!")
    .matches(/^\s*\S[\s\S]*$/, "* Can't starts with white space")
    .max(20, "Maximum 20 Characters Allowed."),
  lastName: Yup.string()
    .required("This is required feild!")
    .matches(/^\s*\S[\s\S]*$/, "* Can't starts with white space")
    .max(20, "Maximum 20 Characters Allowed."),
  phoneNo: Yup.string()
    .required("This is required feild!")
    .matches(/^\s*\S[\s\S]*$/, "* Can't starts with white space")
    .max(10, "Maximum 10 Characters Allowed.")
    .min(6, "Maximum 6 Characters Allowed."),
});
