export const errorHandler = (error) => {
    let errorMessage = "";
    if (error.response.status === 401) {
      errorMessage = error.response.data.error || error.response.data.message;
    } else if (error.response.status === 400) {
      errorMessage = error.response.data.message || error.response.data.error;
    } else if (error.response.status === 404) {
      errorMessage = "ERR_BAD_REQUEST: Request not handled";
    } else if (error.response.status === 422) {
      errorMessage = error.response.data.error.message;
    } else if (error.response.status === 501) {
      errorMessage = error.response.data.error;
    } else if (error.response.status === 500) {
      errorMessage = error.response.data.error;
    } else if (error.code === "ECONNABORTED") {
      errorMessage = "Connection Aboarted";
    } else {
      errorMessage = error.message;
    }
    return errorMessage;
  };
  
export function getImageUrl(name) {
    return new URL(`/src/${name}`, import.meta.url).href;
}