import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setSnackBar = createAsyncThunk("setSnackBar", async (payload) => {
  return payload;
});

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState: {
    isOpen: false,
    severity: "",
    msg: "",
  },
  extraReducers: (builder) => {
    builder.addCase(setSnackBar.fulfilled, (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.severity = action.payload.severity;
      state.msg = action.payload.msg;
    });
  },
});

export default snackBarSlice.reducer;
