import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import landingPageService from "../services/landingPageService";

const initialState = {
  ourSolutions: [],
  careers: [],
};

export const getOurSolutions = createAsyncThunk("@OURSOLUTIONS", async (payload, thunkAPI) => {
  try {
    const { data, status } = await landingPageService.GetOurSolutions(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { ourSolutions: data };
    }
    return thunkAPI.rejectWithValue({ ourSolutions: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ ourSolutions: error.response.data });
  }
});

export const getCareer = createAsyncThunk("@CAREER", async (payload, thunkAPI) => {
  try {
    const { data, status } = await landingPageService.GetCareer(payload);
    // console.log("careers", data);
    if (status === 200) {
      return { careers: data };
    }
    return thunkAPI.rejectWithValue({ careers: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ careers: error.response.data });
  }
});

const landingPageSlice = createSlice({
  name: "landing",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOurSolutions.fulfilled, (state, action) => {
      state.ourSolutions = action.payload;
    });
    builder.addCase(getOurSolutions.rejected, (state, action) => {
      state.ourSolutions = action.payload;
    });
    builder.addCase(getCareer.fulfilled, (state, action) => {
      state.careers = action.payload;
    });
    builder.addCase(getCareer.rejected, (state, action) => {
      state.careers = action.payload;
    });
  },
});

export default landingPageSlice.reducer;
