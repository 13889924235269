import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { getSentimentalDetailedList } from "../../../redux/slices/listSlice";
import moment from "moment";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify";
import { LoadingButton } from "@mui/lab";
import useResponsive from "../../../components/hooks/useResponsive";
import SadEmoji from "../../../assets/img/sad.png";
import HappyEmoji from "../../../assets/img/happy.png";
import { Decrypt } from "../../../components/utils/decrypt";
const DetailedSentimentalStatus = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //   console.log(state);
  const { userRole } = Decrypt();

  const dispatch = useDispatch();
  const [gridRows, setGridRows] = useState([]);
  const [thresholdValues, setThresholdValues] = useState({});
  const isDesktop = useResponsive("up", "lg");

  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    dispatch(enableLoading(true));
    dispatch(getSentimentalDetailedList({ id: state.id }))
      .unwrap()
      .then(({ sentimentalDetailedList }) => {
        dispatch(enableLoading(false));
        setThresholdValues(sentimentalDetailedList.threshold);
        let newMappedArray = sentimentalDetailedList.results.map((row, i) => ({
          serialNo: i + 1,
          // created_at_time: moment(row.created_at).format("DD/MM/YY hh:mm:ss A"),
          updated_at_time: moment(row.updated_at).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        // console.log("sentimentalDetailedList=>", sentimentalDetailedList);
        setGridRows(newMappedArray);
        setRowCount(sentimentalDetailedList.total_count);
      })
      .catch(({ sentimentalDetailedList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: sentimentalDetailedList?.message ? sentimentalDetailedList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch, state]);

  const columns = [
    {
      field: "updated_at_time",
      headerName: "Last Update",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) =>
        row.status == "happy" ? (
          <img src={HappyEmoji} height={36} width={34} />
        ) : row.status == "sad" ? (
          <img src={SadEmoji} height={36} width={34} />
        ) : (
          <Chip variant='gradient' color={""} value={"N/A"} className='py-0.5 px-2 text-[11px] font-medium w-fit' />
        ),
    },
    {
      field: "ram",
      headerName: userRole === "super_admin" ? "RAM" : `RAM (${thresholdValues?.ram})`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "cpu",
      headerName: userRole === "super_admin" ? "CPU" : `CPU (${thresholdValues?.cpu})`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "hardisk",
      headerName: userRole === "super_admin" ? "Hardisk" : `Hardisk (${thresholdValues?.hardisk})`,
      minWidth: 150,
      flex: 1,
    },

    {
      field: "page_memory",
      headerName: userRole === "super_admin" ? "Page Memory" : `Page Memory (${thresholdValues?.hardisk})`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "critical_services",
      headerName: userRole === "super_admin" ? "Critical Services" : `Critical Services (${thresholdValues?.critical_services})`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "latency",
      headerName: userRole === "super_admin" ? "Latency" : `Latency (${thresholdValues?.latency})`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "uptime",
      headerName: userRole === "super_admin" ? "Uptime" : `Uptime (${thresholdValues?.uptime})`,
      minWidth: 150,
      flex: 1,
    },
  ];
  // console.log("thresholdValues", thresholdValues);

  let columnsNameArray = columns.map((el) => el.field);

  const customRenderCell = (cellsArray, params) => {
    // console.log("columnsNameArray", columnsNameArray);
    // console.log("cellsArray", cellsArray);
    const commonItems = cellsArray.filter((item) => columnsNameArray.includes(item));
    if (params.field === "status" || params.field === "updated_at_time") {
      return "";
    } else if (commonItems.includes(params.field)) {
      return "hot";
    } else {
      return "cold";
    }
  };

  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader color='blue' className='p-6 flex  items-center'>
          <Iconify
            icon='eva:arrow-back-outline'
            onClick={() => navigate("/dashboard/emotional-status", { replace: true })}
            sx={{
              cursor: "pointer",
              marginRight: "1rem",
            }}
          />
          <Typography variant='h6' className='text-white'>
            {state.hostname}
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "700px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
              [`.${gridClasses.cell}.cold`]: {
                // backgroundColor: "#71f093",
                color: "#1a3e72",
              },
              [`.${gridClasses.cell}.hot`]: {
                backgroundColor: "#ed6166",
                color: "#ffff",
                fontWeight: 500,
              },
            }}
          >
            <DataGrid
              height={800}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
              //for red cells
              getCellClassName={(params) => {
                const { red_cells } = params.row;
                return userRole === "super_admin" ? "" : customRenderCell(red_cells, params);
                // return params.value >= 15 ? "hot" : "cold";
              }}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailedSentimentalStatus;
