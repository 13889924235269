import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { getLicenseList } from "../../../redux/slices/listSlice";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import moment from "moment";
import Iconify from "../../../components/iconify";
import { useNavigate } from "react-router-dom";
import ViewButton from "../../../components/DataGridTools/ViewButton";
import useResponsive from "../../../components/hooks/useResponsive";

const LicenseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gridRows, setGridRows] = useState([]);
  const isDesktop = useResponsive("up", "lg");

  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    dispatch(enableLoading(true));
    dispatch(getLicenseList())
      .unwrap()
      .then(({ licenseList }) => {
        dispatch(enableLoading(false));

        let newMappedArray = licenseList.results.map((row, i) => ({
          serialNo: i + 1,
          start_date_moment: moment(row.start_date).format("DD/MM/YY"),
          end_date_moment: moment(row.end_date).format("DD/MM/YY"),
          created_at_time: moment(row.created_at).format("DD/MM/YY hh:mm:ss A"),
          updated_at_time: moment(row.updated_at).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRows(newMappedArray);
        setRowCount(licenseList.total_count);
      })
      .catch(({ licenseList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: licenseList?.message ? licenseList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 60, flex: 0.2 },
    {
      field: "action",
      headerName: "Action",
      // headerAlign: "center",
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <ViewButton path={"/dashboard/license-detailed-view"} row={row} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Chip
          variant='gradient'
          color={row.status === true ? "green" : "red"}
          value={row.status === true ? "Active" : "In-Active"}
          className='py-0.5 px-2 text-[11px] font-medium w-fit'
        />
      ),
    },
    {
      field: "total_license",
      headerName: "Total License",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "used_license",
      headerName: "Used License",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "avialable_license",
      headerName: "Available License",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "app_version",
      headerName: "App Version",
      minWidth: 100,
      flex: 1,
    },

    {
      field: "start_date_moment",
      headerName: "Start Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "end_date_moment",
      headerName: "End Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "created_at_time",
      headerName: "Created On",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "updated_at_time",
      headerName: "Updated On",
      minWidth: 200,
      flex: 1,
    },
  ];
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader variant='gradient' color='blue' className='mb-8 p-6'>
          <Typography variant='h6' color='white'>
            License List
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "700px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
            }}
          >
            <DataGrid
              height={800}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default LicenseList;
