/* eslint-disable react/jsx-no-target-blank */
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { HeartIcon } from "@heroicons/react/24/solid";
import { Link } from "@mui/material";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

export function Footer({ brandName, brandLink, routes }) {
  const year = new Date().getFullYear();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsScrolled(false); // Hide the button after scrolling to top
  };
  return (
    <footer className='py-4 bg-blue-100 mt-8'>
      {" "}
      {/* Changed background color to light blue and added padding */}
      <div className='flex w-full flex-wrap items-center text-center justify-center gap-6 px-2'>
        <Typography variant='small' className='font-normal text-inherit items-center text-center'>
          &copy; {year}, made with <HeartIcon className='-mt-0.5 inline-block h-3.5 w-3.5 text-red-600' /> by{" "}
          <Link href={brandLink} className='transition-colors hover:text-blue-500 font-bold'>
            {brandName}
          </Link>{" "}
          for a better web.
        </Typography>
        {/* <ul className='flex items-center gap-4'>
          {routes.map(({ name, path }) => (
            <li key={name}>
              <Typography as='a' href={path} variant='small' className='py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500'>
                {name}
              </Typography>
            </li>
          ))}
        </ul> */}
      </div>
      {isScrolled && (
        <button
          onClick={scrollToTop}
          className='fixed bottom-10 right-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded flex items-center justify-center mb-4'
        >
          <ArrowUpIcon className='h-5 w-5 ' />
        </button>
      )}
    </footer>
  );
}

Footer.defaultProps = {
  brandName: "Team Computers",
  brandLink: "https://www.teamcomputers.com/",
  routes: [
    { name: "Team Computers", path: "https://www.teamcomputers.com/" },
    // { name: "About Us", path: "https://www.creative-tim.com/presentation" },
    // { name: "Blog", path: "https://www.creative-tim.com/blog" },
    // { name: "License", path: "https://www.creative-tim.com/license" },
  ],
};

Footer.propTypes = {
  brandName: PropTypes.string,
  brandLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
