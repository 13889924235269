import { Routes, Route } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
import { Sidenav, DashboardNavbar, Configurator, Footer } from "../../pages/widgets/layout";
import AdminNavConfig from "../../pages/widgets/layout-config/side-nav-admin-config";
import CustomerNavConfig from "../../pages/widgets/layout-config/side-nav-customer-config";
import { useMaterialTailwindController, setOpenConfigurator } from "../../context";
import { Decrypt } from "../utils/decrypt";

export function Dashboard() {
  const [controller, dispatch] = useMaterialTailwindController();
  const { sidenavType } = controller;
  const { userRole } = Decrypt();
  return (
    <div className='min-h-screen bg-blue-gray-50/50'>
      <Sidenav
        routes={userRole === "super_admin" ? AdminNavConfig : CustomerNavConfig}
        brandImg={sidenavType === "dark" ? "/img/logo-ct.png" : "/img/logo-ct-dark.png"}
      />
      <div className='p-4 xl:ml-80'>
        <DashboardNavbar />
        {/* <Configurator /> */}
        {/* <IconButton
          size='lg'
          color='white'
          className='fixed bottom-8 right-8 z-40 rounded-full shadow-blue-gray-900/10'
          ripple={false}
          onClick={() => setOpenConfigurator(dispatch, true)}
        >
          <Cog6ToothIcon className='h-5 w-5' />
        </IconButton> */}
        <Routes>
          {userRole === "super_admin"
            ? AdminNavConfig.map(
                ({ layout, pages }) => layout === "dashboard" && pages.map(({ path, element, i }) => <Route key={i + 1} exact path={path} element={element} />)
              )
            : CustomerNavConfig.map(
                ({ layout, pages }) => layout === "dashboard" && pages.map(({ path, element, i }) => <Route key={i + 1} exact path={path} element={element} />)
              )}
        </Routes>
        <div className='text-blue-gray-600'>{/* <Footer /> */}</div>
      </div>
    </div>
  );
}

Dashboard.displayName = "/src/layout/dashboard.jsx";

export default Dashboard;
