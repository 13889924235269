import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  newPassword: Yup.string("Enter your password")
    .required("New Password is required!")
    .matches(/^\s*\S[\s\S]*$/, "* Can't starts with white space")
    .max(15, "Maximum 15 Characters Allowed.")
    .test("not-same-as-current", "New password can not be same as current password", function (value) {
      return value !== this.parent.currentPassword;
    })
    .nullable(),
  currentPassword: Yup.string("Enter your password")
    .required("Current Password is required!")
    .matches(/^\s*\S[\s\S]*$/, "* Can't starts with white space")
    .max(15, "Maximum 15 Characters Allowed.")
    .nullable(),
});

export const changePasswordInitialValues = {
  newPassword: "",
  currentPassword: "",
};
