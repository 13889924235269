import { Card, CardHeader, CardBody, CardFooter, Typography, Chip } from "@material-tailwind/react";
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enableLoading } from "../../redux/slices/loadSlice";
import { getComplainceList, getSentimentalStatusList } from "../../redux/slices/listSlice";
import moment from "moment";
import { setSnackBar } from "../../redux/slices/snackBarSlice";
import SadEmoji from "../../assets/img/sad.png";
import HappyEmoji from "../../assets/img/happy.png";
export function TabularCard({ cardIconColor, cardIcon, title, value, path }) {
  const dispatch = useDispatch();
  const [gridRowsEmotions, setGridRowsEmotions] = useState([]);
  const [gridRowsCompliance, setGridRowsCompliance] = useState([]);
  const navigate = useNavigate();
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  useEffect(() => {
    dispatch(enableLoading(true));
    dispatch(getComplainceList())
      .unwrap()
      .then(({ complainceList }) => {
        dispatch(enableLoading(false));
        let newMappedArray = complainceList.results.map((row, i) => ({
          serialNo: i + 1,
          last_update_at_time: row.last_update === null ? "N/A" : moment(row.last_update).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRowsCompliance(newMappedArray.slice(0, 5));
      })
      .catch(({ complainceList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: complainceList?.message ? complainceList?.message : "Something Went Wrong!",
          })
        );
      });
    dispatch(getSentimentalStatusList())
      .unwrap()
      .then(({ sentimentalList }) => {
        dispatch(enableLoading(false));
        let newMappedArray = sentimentalList.results.map((row, i) => ({
          serialNo: i + 1,
          last_update_at_time: row.last_update === null ? "N/A" : moment(row.last_update).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRowsEmotions(newMappedArray.slice(0, 5));
      })
      .catch(({ sentimentalList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: sentimentalList?.message ? sentimentalList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch]);
  const columnsForEmotions = [
    { field: "serialNo", headerName: "Sr No.", minWidth: 60, flex: 0.5 },
    {
      field: "hostname",
      headerName: "Host Name",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "last_update_at_time",
      headerName: "Last Update",
      minWidth: 180,
      flex: 1,
    },
  ];
  const columnsForCompliance = [
    { field: "serialNo", headerName: "Sr No.", minWidth: 60, flex: 0.5 },
    {
      field: "hostname",
      headerName: "Host Name",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "last_update_at_time",
      headerName: "Last Update",
      minWidth: 180,
      flex: 1,
    },
  ];
  return (
    <div className='mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-2'>
      <Card
        className='border border-blue-gray-100 shadow-sm'
        // style={{ cursor: path ? "pointer" : "" }}
        // onClick={() => {
        // if (path) navigate(path, { state: { isDashboard: true } });
        //will use isdasjboard true for backbutton on lists
        // }}
      >
        <CardBody className='p-2 text-center' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant='h6' className='font-bold text-blue-gray-600' style={{ marginBottom: "auto" }}>
            Daily Emotions
          </Typography>
          <Divider orientation='horizontal' variant='middle' flexItem sx={{ margin: "6px", marginBottom: "10px" }} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  {columnsForEmotions.map((row) => (
                    <TableCell key={row.serialNo}>{row.headerName}</TableCell>
                  ))}
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gridRowsEmotions.map((row) => (
                  <TableRow key={row.serialNo} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {row.serialNo}
                    </TableCell>
                    <TableCell>{row.hostname}</TableCell>
                    <TableCell style={{ fontSize: "14px" }}>{row.last_update_at_time}</TableCell>
                    <TableCell>
                      {row.status == "happy" ? (
                        <img src={HappyEmoji} height={36} width={34} />
                      ) : row.status == "sad" ? (
                        <img src={SadEmoji} height={36} width={34} />
                      ) : (
                        <Chip variant='gradient' color={""} value={"N/A"} className='py-0.5 px-2 text-[11px] font-medium w-fit' />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
        <button style={{ textDecoration: "underline" }} className='text-blue-500 m-4 mt-0 text-right' onClick={() => navigate("/dashboard/emotional-status")}>
          <Typography>View More</Typography>
        </button>
      </Card>
      <Card
        className='border border-blue-gray-100 shadow-sm'
        // style={{ cursor: path ? "pointer" : "" }}
        // onClick={() => {
        // if (path) navigate(path, { state: { isDashboard: true } });
        //will use isdasjboard true for backbutton on lists
        // }}
      >
        <CardBody className='p-2 text-center' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant='h6' className='font-bold text-blue-gray-600' style={{ marginBottom: "auto" }}>
            Daily Compliance
          </Typography>
          <Divider orientation='horizontal' variant='middle' flexItem sx={{ margin: "6px", marginBottom: "10px" }} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  {columnsForCompliance.map((row) => (
                    <TableCell key={row.serialNo}>{row.headerName}</TableCell>
                  ))}
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gridRowsCompliance.map((row) => (
                  <TableRow key={row.serialNo} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {row.serialNo}
                    </TableCell>
                    <TableCell>{row.hostname}</TableCell>
                    <TableCell style={{ fontSize: "14px" }}>{row.last_update_at_time}</TableCell>
                    <TableCell>
                      {
                        <Chip
                          variant='gradient'
                          color={row.status === true ? "green" : row.status === false ? "red" : ""}
                          value={row.status === true ? "Compliant" : row.status === false ? "Non-Comp" : "N/A"}
                          className='text-[9px] py-2.5 font-small w-fit'
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
        <button style={{ textDecoration: "underline" }} className='text-blue-500 m-4 mt-0 text-right' onClick={() => navigate("/dashboard/compliance-hardening")}>
          <Typography>View More</Typography>
        </button>
      </Card>
    </div>
  );
}

TabularCard.defaultProps = {
  color: "blue",
  footer: null,
};

TabularCard.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  title: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  path: PropTypes.node,
  cardIcon: PropTypes.node,
  cardIconColor: PropTypes.node,
};

TabularCard.displayName = "/src/widgets/cards/statistics-card.jsx";

export default TabularCard;
