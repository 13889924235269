import "./App.css";
import BaseRoutes from "./routes";
import { HelmetProvider } from "react-helmet-async";
// import { StyledEngineProvider } from "@mui/material";
import CircularLoading from "~/components/CustomLoader/CircularProgress";
import CustomSnackbar from "~/components/CustomSnackbar/SnackBar";
import { enableLoading } from "./redux/slices/loadSlice";
import { setSnackBar } from "./redux/slices/snackBarSlice";
import { useDispatch } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  //code for Copy Paste disabling globally
  document.addEventListener("copy", function (e) {
    e.clipboardData.setData("text/plain", "");
    e.clipboardData.setData("text/html", "<b></b>");
    e.preventDefault();
  });

  //code for detect internet connectivity loss
  window.addEventListener("offline", () => {
    dispatch(
      setSnackBar({
        isOpen: true,
        severity: "error",
        msg: "No Internet connection!",
      })
    );
    dispatch(enableLoading(true));
  });
  window.addEventListener("online", () => {
    dispatch(
      setSnackBar({
        isOpen: true,
        severity: "success",
        msg: "Connected Successfully!",
      })
    );
    dispatch(enableLoading(false));
  });
  return (
    <>
      <CustomSnackbar />
      <CircularLoading />
      <BaseRoutes />
    </>
  );
};

export default App;
