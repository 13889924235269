import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import listServices from "../services/listServices";

const initialState = {
  customerList: [],
  licenseList: [],
  announcementList: [],
  solutionList: [],
  hostList: [],
  ticketList: [],
  feedbackList: [],
  solutionRunList: [],
  sentimentalList: [],
  complainceList: [],
  complianceHardningList: [],
  contactUsList: [],
  ourSolutionsList: [],
  blogList: [],
  careerList: [],
  careerapplicationRecivedList: [],
  sentimentalDetailedList: [],
  complainceDetailedList: [],
  testimonialsList: [],
  caseStudyList: [],
};

export const getCustomerList = createAsyncThunk("@CUSTOMER_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetCustomerList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { customerList: data };
    }
    return thunkAPI.rejectWithValue({ customerList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ customerList: error.response.data });
  }
});
export const getHostList = createAsyncThunk("@HOST_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetHostList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { hostList: data };
    }
    return thunkAPI.rejectWithValue({ hostList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ hostList: error.response.data });
  }
});
export const getSolutionList = createAsyncThunk("@SOLUTION_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetSolutionList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { solutionList: data };
    }
    return thunkAPI.rejectWithValue({ solutionList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ solutionList: error.response.data });
  }
});
export const getSolutionRunList = createAsyncThunk("@SOLUTION_RUN_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetSolutionRunList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { solutionRunList: data };
    }
    return thunkAPI.rejectWithValue({ solutionRunList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ solutionRunList: error.response.data });
  }
});
export const getTicketList = createAsyncThunk("@TICKET_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetTicketList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { ticketList: data };
    }
    return thunkAPI.rejectWithValue({ ticketList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ ticketList: error.response.data });
  }
});
export const getFeedBackList = createAsyncThunk("@FEEDBACK_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetFeedBackList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { feedbackList: data };
    }
    return thunkAPI.rejectWithValue({ feedbackList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ feedbackList: error.response.data });
  }
});
export const getSentimentalStatusList = createAsyncThunk("@SENTIMENTAL_STATUS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetSentimentList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { sentimentalList: data };
    }
    return thunkAPI.rejectWithValue({ sentimentalList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ sentimentalList: error.response.data });
  }
});
export const getComplainceList = createAsyncThunk("@COMPLAINCE_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetComplainceList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { complainceList: data };
    }
    return thunkAPI.rejectWithValue({ complainceList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ complainceList: error.response.data });
  }
});
export const getLicenseList = createAsyncThunk("@LICENCE_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetLicenseList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { licenseList: data };
    }
    return thunkAPI.rejectWithValue({ licenseList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ licenseList: error.response.data });
  }
});
export const getAnnouncementList = createAsyncThunk("@ANNOUNCEMENT_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetAnnouncementList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { announcementList: data };
    }
    return thunkAPI.rejectWithValue({ announcementList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ announcementList: error.response.data });
  }
});
export const getContactUsList = createAsyncThunk("@CONTACT_US_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetContactUsList(payload);
    // console.log("getCustomerList", data);
    if (status === 200) {
      return { contactUsList: data };
    }
    return thunkAPI.rejectWithValue({ contactUsList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ contactUsList: error.response.data });
  }
});
export const getBlogsList = createAsyncThunk("@BLOGS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetBlogsList(payload);
    // console.log("blogList", data);
    if (status === 200) {
      return { blogList: data };
    }
    return thunkAPI.rejectWithValue({ blogList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ blogList: error.response.data });
  }
});
export const getCareerList = createAsyncThunk("@CAREER_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetCareerList(payload);
    // console.log("careerList", data);
    if (status === 200) {
      return { careerList: data };
    }
    return thunkAPI.rejectWithValue({ careerList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ careerList: error.response.data });
  }
});
export const getCareerapplicationRecivedList = createAsyncThunk("@CAREER_VACANCIES_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetCareerListVacancies(payload);
    // console.log("careerList", data);
    if (status === 200) {
      return { careerapplicationRecivedList: data };
    }
    return thunkAPI.rejectWithValue({ careerapplicationRecivedList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ careerapplicationRecivedList: error.response.data });
  }
});
export const getSentimentalDetailedList = createAsyncThunk("@SENTIMENTAL_DETAILED_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetSentimentalDetailedList(payload);
    // console.log("careerList", data);
    if (status === 200) {
      return { sentimentalDetailedList: data };
    }
    return thunkAPI.rejectWithValue({ sentimentalDetailedList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ sentimentalDetailedList: error.response.data });
  }
});
export const getDetailedComplainceList = createAsyncThunk("@COMPLAINCE_DETAILED_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetComplainceDetailedList(payload);
    // console.log("careerList", data);
    if (status === 200) {
      return { complainceDetailedList: data };
    }
    return thunkAPI.rejectWithValue({ complainceDetailedList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ complainceDetailedList: error.response.data });
  }
});
export const getOurSolutionList = createAsyncThunk("@OUR_SOLUTIONS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetOurSolutionList(payload);
    // console.log("ourSolutionsList", data);
    if (status === 200) {
      return { ourSolutionsList: data };
    }
    return thunkAPI.rejectWithValue({ ourSolutionsList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ ourSolutionsList: error.response.data });
  }
});
export const getTestimonialsList = createAsyncThunk("@TESTIMONIALS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetTestimonialList(payload);
    // console.log("testimonialsList", data);
    if (status === 200) {
      return { testimonialsList: data };
    }
    return thunkAPI.rejectWithValue({ testimonialsList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ testimonialsList: error.response.data });
  }
});
export const getCaseStudyList = createAsyncThunk("@CASE_STUDY_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await listServices.GetCaseStudyList(payload);
    // console.log("caseStudyList", data);
    if (status === 200) {
      return { caseStudyList: data };
    }
    return thunkAPI.rejectWithValue({ caseStudyList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ caseStudyList: error.response.data });
  }
});

const listSlice = createSlice({
  name: "list",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomerList.fulfilled, (state, action) => {
      state.customerList = action.payload;
    });
    builder.addCase(getCustomerList.rejected, (state, action) => {
      state.customerList = action.payload;
    });
    builder.addCase(getSolutionList.fulfilled, (state, action) => {
      state.solutionList = action.payload;
    });
    builder.addCase(getSolutionList.rejected, (state, action) => {
      state.solutionList = action.payload;
    });
    builder.addCase(getSolutionRunList.fulfilled, (state, action) => {
      state.solutionRunList = action.payload;
    });
    builder.addCase(getSolutionRunList.rejected, (state, action) => {
      state.solutionRunList = action.payload;
    });
    builder.addCase(getFeedBackList.fulfilled, (state, action) => {
      state.feedbackList = action.payload;
    });
    builder.addCase(getFeedBackList.rejected, (state, action) => {
      state.feedbackList = action.payload;
    });
    builder.addCase(getHostList.fulfilled, (state, action) => {
      state.hostList = action.payload;
    });
    builder.addCase(getHostList.rejected, (state, action) => {
      state.hostList = action.payload;
    });
    builder.addCase(getTicketList.fulfilled, (state, action) => {
      state.ticketList = action.payload;
    });
    builder.addCase(getTicketList.rejected, (state, action) => {
      state.ticketList = action.payload;
    });
    builder.addCase(getSentimentalStatusList.fulfilled, (state, action) => {
      state.sentimentalList = action.payload;
    });
    builder.addCase(getSentimentalStatusList.rejected, (state, action) => {
      state.sentimentalList = action.payload;
    });
    builder.addCase(getComplainceList.fulfilled, (state, action) => {
      state.complainceList = action.payload;
    });
    builder.addCase(getComplainceList.rejected, (state, action) => {
      state.complainceList = action.payload;
    });
    builder.addCase(getAnnouncementList.fulfilled, (state, action) => {
      state.announcementList = action.payload;
    });
    builder.addCase(getAnnouncementList.rejected, (state, action) => {
      state.announcementList = action.payload;
    });
    builder.addCase(getLicenseList.fulfilled, (state, action) => {
      state.licenseList = action.payload;
    });
    builder.addCase(getLicenseList.rejected, (state, action) => {
      state.licenseList = action.payload;
    });
    builder.addCase(getContactUsList.fulfilled, (state, action) => {
      state.contactUsList = action.payload;
    });
    builder.addCase(getContactUsList.rejected, (state, action) => {
      state.contactUsList = action.payload;
    });
    builder.addCase(getBlogsList.fulfilled, (state, action) => {
      state.blogList = action.payload;
    });
    builder.addCase(getBlogsList.rejected, (state, action) => {
      state.blogList = action.payload;
    });
    builder.addCase(getOurSolutionList.fulfilled, (state, action) => {
      state.ourSolutionsList = action.payload;
    });
    builder.addCase(getOurSolutionList.rejected, (state, action) => {
      state.ourSolutionsList = action.payload;
    });
    builder.addCase(getCaseStudyList.fulfilled, (state, action) => {
      state.caseStudyList = action.payload;
    });
    builder.addCase(getCaseStudyList.rejected, (state, action) => {
      state.caseStudyList = action.payload;
    });
    builder.addCase(getTestimonialsList.fulfilled, (state, action) => {
      state.testimonialsList = action.payload;
    });
    builder.addCase(getTestimonialsList.rejected, (state, action) => {
      state.testimonialsList = action.payload;
    });
    builder.addCase(getCareerList.fulfilled, (state, action) => {
      state.careerList = action.payload;
    });
    builder.addCase(getCareerList.rejected, (state, action) => {
      state.careerList = action.payload;
    });
    builder.addCase(getCareerapplicationRecivedList.fulfilled, (state, action) => {
      state.careerapplicationRecivedList = action.payload;
    });
    builder.addCase(getCareerapplicationRecivedList.rejected, (state, action) => {
      state.careerapplicationRecivedList = action.payload;
    });
    builder.addCase(getSentimentalDetailedList.fulfilled, (state, action) => {
      state.sentimentalDetailedList = action.payload;
    });
    builder.addCase(getSentimentalDetailedList.rejected, (state, action) => {
      state.sentimentalDetailedList = action.payload;
    });
    builder.addCase(getDetailedComplainceList.fulfilled, (state, action) => {
      state.complainceDetailedList = action.payload;
    });
    builder.addCase(getDetailedComplainceList.rejected, (state, action) => {
      state.complainceDetailedList = action.payload;
    });
  },
});

export default listSlice.reducer;
