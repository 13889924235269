import { useLocation, Link } from "react-router-dom";
import { Navbar, Typography, Button, IconButton, Breadcrumbs, Input, Menu, MenuHandler, MenuList, MenuItem, Avatar } from "@material-tailwind/react";
import { UserCircleIcon, Cog6ToothIcon, BellIcon, ClockIcon, CreditCardIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { useMaterialTailwindController, setOpenConfigurator, setOpenSidenav } from "../../../context";
import { ProfileMenu } from "./ProfileMenu";

export function DashboardNavbar() {
  const [controller, dispatch] = useMaterialTailwindController();
  const { fixedNavbar, openSidenav } = controller;
  const { pathname } = useLocation();
  const [layout, page] = pathname.split("/").filter((el) => el !== "");

  return (
    <Navbar
      color={fixedNavbar ? "white" : "transparent"}
      className={`rounded-xl transition-all ${fixedNavbar ? "sticky top-4 z-40 py-3 shadow-md shadow-blue-gray-500/5" : "px-0 py-1"}`}
      fullWidth
      blurred={fixedNavbar}
    >
      <div className='flex  justify-between gap-6 md:flex-row md:items-center'>
        <div className='capitalize'>
          <Breadcrumbs className={`bg-transparent p-0 transition-all ${fixedNavbar ? "mt-1" : ""}`}>
            <Link to={`/${layout}/home`}>
              <Typography variant='small' color='blue-gray' className='font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100'>
                {layout}
              </Typography>
            </Link>
            <Typography variant='small' color='blue-gray' className='font-normal'>
              {page}
            </Typography>
          </Breadcrumbs>
          <Typography variant='h6' color='blue-gray'>
            {page}
          </Typography>
        </div>
        <div className='flex items-center'>
          {!openSidenav && (
            <IconButton variant='text' color='blue-gray' className='grid xl:hidden' onClick={() => setOpenSidenav(dispatch, !openSidenav)}>
              <Bars3Icon strokeWidth={3} className='h-6 w-6 text-blue-gray-500' />
            </IconButton>
          )}

          <ProfileMenu />
        </div>
      </div>
    </Navbar>
  );
}

DashboardNavbar.displayName = "/src/widgets/layout/dashboard-navbar.jsx";

export default DashboardNavbar;
