import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTicketList } from "../../../redux/slices/listSlice";
import moment from "moment";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import Iconify from "../../../components/iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { Decrypt } from "../../../components/utils/decrypt";
import { LoadingButton } from "@mui/lab";
import ViewButton from "../../../components/DataGridTools/ViewButton";
import useResponsive from "../../../components/hooks/useResponsive";

const TicketsList = () => {
  const location = useLocation();
  const isDashboard = location && location?.state?.isDashboard;

  const { userRole } = Decrypt();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [gridRows, setGridRows] = useState([]);
  const isDesktop = useResponsive("up", "lg");

  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    dispatch(enableLoading(true));

    dispatch(getTicketList())
      .unwrap()
      .then(({ ticketList }) => {
        dispatch(enableLoading(false));

        let newMappedArray = ticketList.results.map((row, i) => ({
          serialNo: i + 1,
          created_at_time: moment(row.created_at).format("DD/MM/YY hh:mm:ss A"),
          updated_at_time: moment(row.updated_at).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRows(newMappedArray);
        setRowCount(ticketList.total_count);
      })
      .catch(({ ticketList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: ticketList?.message ? ticketList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch]);
  const columns = [
    {
      field: "action",
      headerName: "Action",
      // headerAlign: "center",
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <ViewButton path={"/dashboard/tickets-detailed-view"} row={row} />;
      },
    },

    {
      field: "host",
      headerName: "Host",
      minWidth: 200,
      flex: 0.5,
    },
  ];
  if (userRole === "super_admin") {
    columns.push({
      field: "customer",
      headerName: "Customer",
      minWidth: 150,
      flex: 1,
    });
  }
  if (userRole === "super_admin") {
    columns.unshift({ field: "id", headerName: "ID", minWidth: 60, flex: 0.2 });
  } else if (userRole === "customer") {
    columns.unshift({ field: "serialNo", headerName: "Sr No.", minWidth: 60, flex: 0.2 });
  }
  columns.push(
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "ticket_id",
      headerName: "Ticket ID",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "created_at_time",
      headerName: "Created On",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "updated_at_time",
      headerName: "Updated On",
      minWidth: 200,
      flex: 1,
    }
  );
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      {isDashboard === true && (
        <Stack direction={"row"} sx={{ mb: 1 }}>
          <LoadingButton variant='filled' onClick={() => navigate("/dashboard/home", { replace: true })} sx={{ backgroundColor: "white", marginLeft: "6px" }}>
            <Iconify icon='icon-park-outline:back' />
            <Typography variant='h7'>Back to Dashboard</Typography>
          </LoadingButton>
        </Stack>
      )}
      <Card>
        <CardHeader variant='gradient' color='blue' className='mb-8 p-6'>
          <Typography variant='h6' color='white'>
            Tickets List
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "700px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
            }}
          >
            <DataGrid
              height={800}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default TicketsList;
