/* eslint-disable react/prop-types */
import React from "react";
import { IconButton, Tooltip, Stack } from "@mui/material"; // Assuming you are using Material-UI
import { Icon } from "@iconify/react"; // Assuming you are using Iconify for icons
import { useNavigate } from "react-router-dom";

const ViewButton = ({ row, path }) => {
  const navigate = useNavigate();

  const handleViewClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(path, { state: row });
  };

  return (
    <Stack sx={{ width: "100%" }} direction='row' columnGap={1}>
      <Tooltip title='View'>
        <IconButton
          size='medium'
          color='inherit'
          onMouseDown={handleViewClick} // Use onMouseDown instead of onClick
          onFocus={(event) => event.stopPropagation()} // Stop focus event propagation
        >
          <Icon icon='mdi:eye' />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ViewButton;
