import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../../components/hooks/useResponsive";

const FAQsList = () => {
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");

  const [gridRows, setGridRows] = useState([
    {
      id: 1,
      company_name: "new comp",
      host_name: "Google",
      mac_address: "18:09:21:21",
      ratings: 3.8,
      solution_name: "DataLocker",
      resolution_type: "type 1",
      date: "12/02/2024",
    },
    {
      id: 2,
      company_name: "new comp",
      host_name: "Google",
      mac_address: "18:09:21:21",
      ratings: 3.8,
      solution_name: "Google",
      resolution_type: "type 1",
      date: "12/02/2024",
    },
  ]);
  const columns = [
    { field: "id", headerName: "ID", minWidth: 60, flex: 0.2 },

    {
      field: "company_name",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "host_name",
      headerName: "Host Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "mac_address",
      headerName: "Mac Address",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "solution_name",
      headerName: "Solution Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "ratings",
      headerName: "Ratings",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "resolution_type",
      headerName: "Resolution Type",
      minWidth: 150,
      flex: 1,
    },

    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 1,
    },
  ];
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader variant='gradient' color='blue' className='mb-8 p-6'>
          <Typography variant='h6' color='white'>
            FAQs List
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "400px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
            }}
          >
            <DataGrid
              height={400}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default FAQsList;
