import http from "./apiSevice";

class AdminServices {
  async LoginUser(payload) {
    const response = await http.post(`auth/api/v1/login/`, payload);
    return response;
  }
  async ForgetPassword(payload) {
    const response = await http.post(`auth/api/v1/reset/password/`, payload);
    return response;
  }
  async LogoutUser(payload) {
    const response = await http.post(`auth/api/v1/logout/`, payload);
    return response;
  }
  async GetUserInforMation(payload) {
    const response = await http.get(`auth/api/v1/user/?id=${payload}`);
    return response;
  }
  async ChangePassword(payload) {
    const response = await http.post("auth/api/v1/change/password/", payload);
    return response;
  }
  async GetDashboardCardData(payload) {
    const response = await http.get("portal/api/v1/dashboard/cards/");
    return response;
  }
  async GetDashboardChartData({ type }) {
    const response = await http.get(`portal/api/v1/dashboard/graph/?type=${type}`);
    return response;
  }
  async DownloadReports(payload) {
    const { type, start_date, end_date } = payload;
    if (start_date) {
      const response = await http.get(`portal/api/v1/reports/data/?type=${type}&start_date=${start_date}&end_date=${end_date}`);
      return response;
    } else {
      const response = await http.get(`portal/api/v1/reports/data/?type=${type}`);
      return response;
    }
  }
  async DownloadComplainceReport({ id }) {
    const response = await http.get(`portal/api/v1/complaince/host/report/?complaince_id=${id}`);
    return response;
  }
}

export default new AdminServices();
