import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import websiteServices from "../services/websiteServices";
import { errorHandler } from "../../utils";
import { setMessage } from "./message";
import { setSnackBar } from "./snackBarSlice";

const initialState = {
  ourSolutionsList: [],
  blogList: [],
  careerList: [],
  testimonialsList: [],
  caseStudyList: [],
  responseOfContactUS: {},
  respApplicationSubmit: {},
};

export const getBlogsList = createAsyncThunk("@BLOGS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.GetBlogsList(payload);
    // console.log("blogList", data);
    if (status === 200) {
      return { blogList: data };
    }
    return thunkAPI.rejectWithValue({ blogList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ blogList: error.response.data });
  }
});
export const getCareerList = createAsyncThunk("@CAREER_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.GetCareerList(payload);
    // console.log("careerList", data);
    if (status === 200) {
      return { careerList: data };
    }
    return thunkAPI.rejectWithValue({ careerList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ careerList: error.response.data });
  }
});
export const getOurSolutionList = createAsyncThunk("@OUR_SOLUTIONS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.GetOurSolutionList(payload);
    // console.log("ourSolutionsList", data);
    if (status === 200) {
      return { ourSolutionsList: data };
    }
    return thunkAPI.rejectWithValue({ ourSolutionsList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ ourSolutionsList: error.response.data });
  }
});
export const getTestimonialsList = createAsyncThunk("@TESTIMONIALS_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.GetTestimonialList(payload);
    // console.log("testimonialsList", data);
    if (status === 200) {
      return { testimonialsList: data };
    }
    return thunkAPI.rejectWithValue({ testimonialsList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ testimonialsList: error.response.data });
  }
});
export const getCaseStudyList = createAsyncThunk("@CASE_STUDY_LIST", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.GetCaseStudyList(payload);
    // console.log("caseStudyList", data);
    if (status === 200) {
      return { caseStudyList: data };
    }
    return thunkAPI.rejectWithValue({ caseStudyList: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ caseStudyList: error.response.data });
  }
});

export const submitContactUsForm = createAsyncThunk("@CONTACT_US_FORM", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.SubmitContactUsForm(payload);
    // console.log("caseStudyList", data);

    return { responseOfContactUS: data };

    // return thunkAPI.rejectWithValue({ responseOfContactUS: data });
  } catch (error) {
    console.log(error, "error");
    let message = errorHandler(error);
    thunkAPI.dispatch(setMessage(message));
    thunkAPI.dispatch(setSnackBar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: message }));
    return thunkAPI.rejectWithValue();
    // return thunkAPI.rejectWithValue({ responseOfContactUS: error.response.data });
  }
});
export const submitCareerApplication = createAsyncThunk("@APPLICATION_FORM_SUBMIT", async (payload, thunkAPI) => {
  try {
    const { data, status } = await websiteServices.SubmitApplicationForm(payload);
    // console.log("caseStudyList", data);

    return { respApplicationSubmit: data };

    // return thunkAPI.rejectWithValue({ respApplicationSubmit: data });
  } catch (error) {
    console.log(error, "error");
    let message = errorHandler(error);
    thunkAPI.dispatch(setMessage(message));
    thunkAPI.dispatch(setSnackBar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: message }));
    return thunkAPI.rejectWithValue();
    // return thunkAPI.rejectWithValue({ respApplicationSubmit: error.response.data });
  }
});

const websiteSlice = createSlice({
  name: "website",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBlogsList.fulfilled, (state, action) => {
      state.blogList = action.payload;
    });
    builder.addCase(getBlogsList.rejected, (state, action) => {
      state.blogList = action.payload;
    });
    builder.addCase(getOurSolutionList.fulfilled, (state, action) => {
      state.ourSolutionsList = action.payload;
    });
    builder.addCase(getOurSolutionList.rejected, (state, action) => {
      state.ourSolutionsList = action.payload;
    });
    builder.addCase(getCaseStudyList.fulfilled, (state, action) => {
      state.caseStudyList = action.payload;
    });
    builder.addCase(getCaseStudyList.rejected, (state, action) => {
      state.caseStudyList = action.payload;
    });
    builder.addCase(getTestimonialsList.fulfilled, (state, action) => {
      state.testimonialsList = action.payload;
    });
    builder.addCase(getTestimonialsList.rejected, (state, action) => {
      state.testimonialsList = action.payload;
    });
    builder.addCase(getCareerList.fulfilled, (state, action) => {
      state.careerList = action.payload;
    });
    builder.addCase(getCareerList.rejected, (state, action) => {
      state.careerList = action.payload;
    });
    builder.addCase(submitContactUsForm.fulfilled, (state, action) => {
      state.responseOfContactUS = action.payload;
    });
    builder.addCase(submitContactUsForm.rejected, (state, action) => {
      state.responseOfContactUS = action.payload;
    });
    builder.addCase(submitCareerApplication.fulfilled, (state, action) => {
      state.respApplicationSubmit = action.payload;
    });
    builder.addCase(submitCareerApplication.rejected, (state, action) => {
      state.respApplicationSubmit = action.payload;
    });
  },
});

export default websiteSlice.reducer;
