/* eslint-disable react/prop-types */
import { Card, CardBody, CardHeader, CardFooter, Avatar, Typography, Tabs, TabsHeader, Tab, Switch, Button } from "@material-tailwind/react";
import { Decrypt } from "../../../components/utils/decrypt";
import { useEffect, useRef, useState } from "react";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { fetchUserInformation } from "../../../redux/slices/adminSlice";
import { useDispatch } from "react-redux";
import Iconify from "../../../components/iconify/iconify";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import { editProfileSchema } from "./editFormHelper";

const Profile = () => {
  const { userId } = Decrypt();
  const formRef = useRef();
  const [isEditing, setIsEditing] = useState(true);
  const { profileImage, userRole } = Decrypt();

  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    dispatch(enableLoading(true));
    dispatch(fetchUserInformation(userId))
      .unwrap()
      .then(({ userInformation }) => {
        // console.log("userInformation", userInformation.results[0].first_name);
        setUserData(userInformation?.results[0]);
        dispatch(enableLoading(false));
        // dispatch(
        //   setSnackBar({
        //     isOpen: true,
        //     severity: "success",
        //     msg: userInformation?.message,
        //   })
        // );
      })
      .catch(({ userInformation }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: userInformation?.message ? userInformation?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch, userId]);
  const handleEditProfile = () => {
    const { values, setSubmitting, resetForm } = formRef.current;
    // console.log("values", values);
  };

  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url('../../assets/img/background-image.png')] bg-cover	bg-center">
        <div className='absolute inset-0 h-full w-full bg-gray-900/75' />
      </div>
      <Card className='mx-3 -mt-16 mb-6 lg:mx-4 border border-blue-gray-100'>
        <CardBody className='p-4'>
          <div className='mb-10 flex items-center justify-between flex-wrap gap-6'>
            <div className='flex items-center gap-6'>
              <Avatar
                src={
                  profileImage
                    ? profileImage
                    : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                }
                alt='bruce-mars'
                size='xl'
                variant='rounded'
                className='rounded-lg shadow-lg shadow-blue-gray-500/40'
              />
              <div>
                <Typography variant='h5' color='blue-gray' className='mb-1'>
                  {userData?.first_name?.toUpperCase() + " " + userData?.last_name?.toUpperCase()}
                </Typography>
                <Typography variant='caption' color='blue-gray' className='mb-1'>
                  {userData?.user_type?.toUpperCase()}
                </Typography>
              </div>
            </div>
            <div className='w-99'>
              {isEditing === true && (
                <Tooltip title='Edit'>
                  <IconButton size='large' color='inherit' onClick={() => setIsEditing(false)}>
                    <Iconify icon={"ic:twotone-mode-edit-outline"} />
                  </IconButton>
                </Tooltip>
              )}
              {isEditing === false && (
                <Tooltip title='Cancel'>
                  <IconButton size='large' color='inherit' onClick={() => setIsEditing(true)}>
                    <Iconify icon={"mdi:cancel-bold"} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          {/* implement edit form */}
          <Formik
            initialValues={{
              firstName: userData.first_name,
              lastName: userData.last_name,
              phoneNo: userData.phone_number,
              email: userData.email,
              totalLicense: userData.license_data?.total_license,
              usedLicense: userData.license_data?.used_license,
              spareLicense: userData.license_data?.spare_license,
            }}
            validationSchema={editProfileSchema}
            innerRef={formRef}
            onSubmit={handleEditProfile}
            enableReinitialize
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isSubmitting, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className='mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6'>
                  <div className='sm:col-span-2'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>First Name</label>
                    <div className='mt-2'>
                      <TextField
                        value={values.firstName}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEditing}
                        variant='outlined'
                        name='firstName'
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            background: isEditing === true ? "#f3f4f6" : "#fff", // Change background color to grey
                            height: "40px", // Adjust height as needed
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px", // Adjust border radius if necessary
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10px", // Adjust padding if needed
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Last Name</label>
                    <div className='mt-2'>
                      <TextField
                        value={values.lastName}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEditing}
                        variant='outlined'
                        name='lastName'
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            background: isEditing === true ? "#f3f4f6" : "#fff", // Change background color to grey
                            height: "40px", // Adjust height as needed
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px", // Adjust border radius if necessary
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10px", // Adjust padding if needed
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Phone No</label>
                    <div className='mt-2'>
                      <TextField
                        value={values.phoneNo}
                        error={touched.phoneNo && Boolean(errors.phoneNo)}
                        helperText={touched.phoneNo && errors.phoneNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEditing}
                        variant='outlined'
                        name='phoneNo'
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            background: isEditing === true ? "#f3f4f6" : "#fff", // Change background color to grey
                            height: "40px", // Adjust height as needed
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px", // Adjust border radius if necessary
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10px", // Adjust padding if needed
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className='sm:col-span-2'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>Email</label>
                    <div className='mt-2'>
                      <TextField
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        variant='outlined'
                        name='email'
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            background: "#f3f4f6",
                            height: "40px", // Adjust height as needed
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px", // Adjust border radius if necessary
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10px", // Adjust padding if needed
                          },
                        }}
                      />
                    </div>
                  </div>
                  {isEditing === false && userRole === "super_admin" ? (
                    <div className='sm:col-span-4' style={{ justifySelf: "end" }}>
                      <Button type='submit' className='mt-8' style={{ backgroundColor: "#1983c4", color: "#fff" }} disabled={isSubmitting}>
                        Submit
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {userRole === "customer" ? (
                  <div className='mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6'>
                    <div className='sm:col-span-2'>
                      <label className='block text-sm font-medium leading-6 text-gray-900'>Total License</label>
                      <div className='mt-2'>
                        <TextField
                          value={values.totalLicense}
                          error={touched.totalLicense && Boolean(errors.totalLicense)}
                          helperText={touched.totalLicense && errors.totalLicense}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                          variant='outlined'
                          name='totalLicense'
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f3f4f6",
                              height: "40px", // Adjust height as needed
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px", // Adjust border radius if necessary
                            },

                            "& .MuiOutlinedInput-input": {
                              padding: "10px", // Adjust padding if needed
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-2'>
                      <label className='block text-sm font-medium leading-6 text-gray-900'>Used License</label>
                      <div className='mt-2'>
                        <TextField
                          value={values.usedLicense}
                          error={touched.usedLicense && Boolean(errors.usedLicense)}
                          helperText={touched.usedLicense && errors.usedLicense}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                          variant='outlined'
                          name='usedLicense'
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f3f4f6",
                              height: "40px", // Adjust height as needed
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px", // Adjust border radius if necessary
                            },

                            "& .MuiOutlinedInput-input": {
                              padding: "10px", // Adjust padding if needed
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-2'>
                      <label className='block text-sm font-medium leading-6 text-gray-900'>Spare License</label>
                      <div className='mt-2'>
                        <TextField
                          value={values.spareLicense}
                          error={touched.spareLicense && Boolean(errors.spareLicense)}
                          helperText={touched.spareLicense && errors.spareLicense}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                          variant='outlined'
                          name='spareLicense'
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f3f4f6",
                              height: "40px", // Adjust height as needed
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px", // Adjust border radius if necessary
                            },

                            "& .MuiOutlinedInput-input": {
                              padding: "10px", // Adjust padding if needed
                            },
                          }}
                        />
                      </div>
                    </div>
                    {isEditing === false && userRole === "customer" ? (
                      <div className='sm:col-span-8' style={{ justifySelf: "end" }}>
                        <Button type='submit' className='mt-8' style={{ backgroundColor: "#1983c4", color: "#fff" }} disabled={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
};

export default Profile;
