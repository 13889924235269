import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, IconButton, Typography } from "@material-tailwind/react";
import { useMaterialTailwindController, setOpenSidenav } from "../../../context";
import productLogo from "../../../assets/img/zero-fai-logo-transparent.png";

export function Sidenav({ brandImg, brandName, routes }) {
  // console.log("routes=>", routes[0].pages);
  const [controller, dispatch] = useMaterialTailwindController();
  const { sidenavColor, sidenavType, openSidenav } = controller;
  const sidenavTypes = {
    blue: "bg-gradient-to-br from-green-400 to-blue-400",
    white: "bg-white shadow-sm",
    transparent: "bg-transparent",
  };

  return (
    <aside
      className={`${sidenavTypes[sidenavType]} ${
        openSidenav ? "translate-x-0" : "-translate-x-80"
      } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 border border-blue-gray-100`}
    >
      <div className='overflow-y-auto h-full'>
        <div className={`relative`}>
          <Link to='/' className='m-4 py-6 px-8 text-center'>
            {/* <Typography variant='h6' color={sidenavType === "blue" ? "white" : "blue-gray"}>
            {brandImg}
          </Typography> */}
            <img className='rounded-lg  object-right shadow-blue-gray-500/50' src={productLogo} alt='avatar' size='xxl' color='blue' style={{ width: "110px", marginLeft: 60 }} />
          </Link>
          <IconButton
            variant='text'
            color='white'
            size='sm'
            ripple={false}
            className='absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden'
            onClick={() => setOpenSidenav(dispatch, false)}
          >
            <XMarkIcon strokeWidth={2.5} className='h-5 w-5 text-black' />
          </IconButton>
        </div>
        <div className='m-4'>
          {routes.map(({ layout, title, pages }, key) => (
            <ul key={key} className='mb-4 flex flex-col gap-1'>
              {title && (
                <li className='mx-3.5 mt-4 mb-2'>
                  <Typography variant='small' color={sidenavType === "blue" ? "white" : "blue-gray"} className='font-black uppercase opacity-75'>
                    {title}
                  </Typography>
                </li>
              )}
              {pages
                .filter(
                  (el) =>
                    el.path !== "/profile" &&
                    el.path !== "/change-password" &&
                    el.path !== "/carer-applicationRecivedList" &&
                    el.path !== "/emotion-detailed-list" &&
                    el.path !== "/detailed-hardenning-list"
                )
                .map(({ icon, name, path }) => (
                  <li key={name}>
                    <NavLink to={`/${layout}${path}`}>
                      {({ isActive }) => (
                        <Button
                          variant={isActive ? "gradient" : "text"}
                          color={isActive ? sidenavColor : sidenavType === "blue" ? "white" : "blue-gray"}
                          className='flex items-center gap-4 px-4 capitalize'
                          fullWidth
                        >
                          {icon}
                          <Typography color='inherit' className='font-medium capitalize'>
                            {name}
                          </Typography>
                        </Button>
                      )}
                    </NavLink>
                  </li>
                ))}
            </ul>
          ))}
        </div>
      </div>
    </aside>
  );
}

Sidenav.defaultProps = {
  brandImg: productLogo,
  brandName: "Zero-FAI",
};

Sidenav.propTypes = {
  brandImg: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Sidenav.displayName = "/src/widgets/layout/sidnave.jsx";

export default Sidenav;
