import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getComplainceList, getSentimentalStatusList } from "../../../redux/slices/listSlice";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import { Decrypt } from "../../../components/utils/decrypt";
import Iconify from "../../../components/iconify";
import { useNavigate } from "react-router-dom";
import ViewButton from "../../../components/DataGridTools/ViewButton";
import useResponsive from "../../../components/hooks/useResponsive";
const ComplianceHardeningList = () => {
  const { userRole } = Decrypt();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [gridRows, setGridRows] = useState([]);
  const isDesktop = useResponsive("up", "lg");

  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    dispatch(enableLoading(true));

    dispatch(getComplainceList())
      .unwrap()
      .then(({ complainceList }) => {
        dispatch(enableLoading(false));

        let newMappedArray = complainceList.results.map((row, i) => ({
          serialNo: i + 1,
          last_update_at_time: row.last_update === null ? "N/A" : moment(row.last_update).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRows(newMappedArray);
        setRowCount(complainceList.total_count);
      })
      .catch(({ complainceList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: complainceList?.message ? complainceList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch]);

  const columns = [
    { field: "serialNo", headerName: "Sr No.", minWidth: 60, flex: 0.2 },
    {
      field: "action",
      headerName: "Action",
      // headerAlign: "center",
      minWidth: 100,
      sortable: false,

      filterable: false,
      renderCell: ({ row }) => {
        return <ViewButton path={"/dashboard/detailed-hardenning-list"} row={row} />;
      },
    },
    {
      field: "hostname",
      headerName: "Host Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Chip
          variant='gradient'
          color={row.status === true ? "green" : row.status === false ? "red" : ""}
          value={row.status === true ? "Compliant" : row.status === false ? "Non-Compliant" : "N/A"}
          className='py-0.5 px-2 text-[11px] font-medium w-fit'
        />
      ),
    },
  ];
  if (userRole === "super_admin") {
    columns.push({
      field: "customer",
      headerName: "Customer",
      minWidth: 150,
      flex: 1,
    });
  }

  // if (userRole === "super_admin") {
  //   columns.unshift({ field: "id", headerName: "ID", minWidth: 60, flex: 0.2 });
  // } else if (userRole === "customer") {
  //   columns.unshift({ field: "serialNo", headerName: "Sr No.", minWidth: 60, flex: 0.2 });
  // }
  columns.push({
    field: "last_update_at_time",
    headerName: "Last Update",
    minWidth: 200,
    flex: 1,
  });
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader variant='gradient' color='blue' className='mb-8 p-6'>
          <Typography variant='h6' color='white'>
            Compliance Hardening List
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "700px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
            }}
          >
            <DataGrid
              height={800}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              disableRowSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default ComplianceHardeningList;
