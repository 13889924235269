import {
  HomeIcon,
  UserCircleIcon,
  NewspaperIcon,
  ComputerDesktopIcon,
  BeakerIcon,
  TicketIcon,
  RocketLaunchIcon,
  ChatBubbleBottomCenterIcon,
  UserGroupIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { Home } from "../../dashboard";
import CustomerList from "../../admin-modules/customers";
import LicenseList from "../../admin-modules/licenses";
import HostsList from "../../admin-customer-modules/hosts";
import SolutionsList from "../../admin-customer-modules/solutions";
import TicketsList from "../../admin-customer-modules/tickets";
import SolutionsRunList from "../../admin-customer-modules/solutions-run";
import FeedBacksList from "../../admin-customer-modules/feedbacks";
import SentimentalList from "../../admin-customer-modules/emotional-status";
import ComplianceHardningList from "../../admin-customer-modules/compliance-hardning";
import Profile from "../../authentication/profile";
import ChangePassword from "../../authentication/change-password";
import DetailedSentimentalStatus from "../../admin-customer-modules/emotional-status/detailedList";
import DetailedComplianceHardning from "../../admin-customer-modules/compliance-hardning/detailedList";
import Reports from "../../admin-customer-modules/reports";
const icon = {
  className: "w-5 h-5 text-inherit",
};

const CustomerNavConfig = [
  {
    layout: "dashboard",
    pages: [
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/detailed-hardenning-list",
        element: <DetailedComplianceHardning />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/emotion-detailed-list",
        element: <DetailedSentimentalStatus />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/profile",
        element: <Profile />,
      },
      {
        // icon: <HomeIcon {...icon} />,
        // name: "dashboard",
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/home",
        element: <Home />,
      },

      {
        icon: <BeakerIcon {...icon} />,
        name: "solutions",
        path: "/solutions",
        element: <SolutionsList />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: "hosts",
        path: "/hosts",
        element: <HostsList />,
      },

      {
        icon: <TicketIcon {...icon} />,
        name: "tickets",
        path: "/tickets",
        element: <TicketsList />,
      },
      {
        icon: <ChatBubbleBottomCenterIcon {...icon} />,
        name: "feedbacks",
        path: "/feedbacks",
        element: <FeedBacksList />,
      },
      {
        icon: <RocketLaunchIcon {...icon} />,
        name: "solution run",
        path: "/solution-run",
        element: <SolutionsRunList />,
      },

      {
        icon: <UserGroupIcon {...icon} />,
        name: "Emotions  Status",
        path: "/emotional-status",
        element: <SentimentalList />,
      },
      {
        icon: <ShieldCheckIcon {...icon} />,
        name: "compliance hardening",
        path: "/compliance-hardening",
        element: <ComplianceHardningList />,
      },
      {
        icon: <DocumentTextIcon {...icon} />,
        name: "reports",
        path: "/reports",
        element: <Reports />,
      },
    ],
  },
];

export default CustomerNavConfig;
