import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = { loading: false };

const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    enableLoading: (state, { payload }) => {
      state.loading = payload;
    },
    disableLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

const { reducer, actions } = loadSlice;
export const { enableLoading, disableLoading } = actions;
export default reducer;
