import React from "react";
import PropTypes from "prop-types";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const InfraGridToolbar = ({ setFilterButtonEl }) => {
  return (
    <GridToolbarContainer sx={{ display: "flex", p: 1, columnGap: 3 }}>
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      {/* <GridToolbarExport csvOptions={{ allColumns: true }} printOptions={{ disableToolbarButton: true }} /> */}
    </GridToolbarContainer>
  );
};

InfraGridToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};
export default InfraGridToolbar;
