import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminService from "../services/adminService";

const initialState = {
  loginData: {},
  reportsData: {},
  logOutData: {},
  changedPasswordData: {},
  userInformation: {},
  passwordResetData: {},
  complainceReportDownload: {},
  dashboardCardData: {},
  dashboardChartData: {},
};

export const loginUser = createAsyncThunk("@LOGIN_USER", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.LoginUser(payload);
    //     console.log("datalogin", data);
    if (data.status === true) {
      return { loginData: data };
    }
    return thunkAPI.rejectWithValue({ loginData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ loginData: error.response.data });
  }
});
export const forgetPassword = createAsyncThunk("@FORGET_PASSWORD", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.ForgetPassword(payload);
    //     console.log("passwordResetData", data);
    if (data.status === true) {
      return { passwordResetData: data };
    }
    return thunkAPI.rejectWithValue({ passwordResetData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ passwordResetData: error.response.data });
  }
});
export const logOutUser = createAsyncThunk("@LOGOUT_USER", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.LogoutUser(payload);
    // console.log("logOutUser", data);
    if (data.status === true) {
      return { logOutData: data };
    }
    return thunkAPI.rejectWithValue({ logOutData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ logOutData: error.response.data });
  }
});
export const changePassword = createAsyncThunk("@CHANGE_PASSWORD", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.ChangePassword(payload);

    if (data.status === true) {
      return { changedPasswordData: data };
    }
    return thunkAPI.rejectWithValue({ changedPasswordData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ changedPasswordData: error.response.data });
  }
});
export const fetchUserInformation = createAsyncThunk("@FETCH_USER_INFRMATION", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.GetUserInforMation(payload);
    if (data.results) {
      return { userInformation: data };
    }
    return thunkAPI.rejectWithValue({ userInformation: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ userInformation: error.response.data });
  }
});
export const getDashboardCardData = createAsyncThunk("@DASHBOARD_CARD_DATA", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.GetDashboardCardData(payload);
    if (data) {
      return { dashboardCardData: data };
    }
    return thunkAPI.rejectWithValue({ dashboardCardData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ dashboardCardData: error.response.data });
  }
});
export const getDashboardChartData = createAsyncThunk("@DASHBOARD_CHART_DATA", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.GetDashboardChartData(payload);
    if (data) {
      return { dashboardChartData: data };
    }
    return thunkAPI.rejectWithValue({ dashboardChartData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ dashboardChartData: error.response.data });
  }
});
export const downloadReports = createAsyncThunk("@DOWNLOAD_REPORTS", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.DownloadReports(payload);
    if (data) {
      return { reportsData: data };
    }
    return thunkAPI.rejectWithValue({ reportsData: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ reportsData: error.response.data });
  }
});
export const downloadComplaiceReport = createAsyncThunk("@COMPLAINCE_REPORT_DOWNLOAD", async (payload, thunkAPI) => {
  try {
    const { data } = await adminService.DownloadComplainceReport(payload);
    if (data) {
      return { complainceReportDownload: data };
    }
    return thunkAPI.rejectWithValue({ complainceReportDownload: data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ complainceReportDownload: error.response.data });
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginData = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginData = action.payload;
    });
    builder.addCase(logOutUser.fulfilled, (state, action) => {
      state.logOutData = action.payload;
    });
    builder.addCase(logOutUser.rejected, (state, action) => {
      state.logOutData = action.payload;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changedPasswordData = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changedPasswordData = action.payload;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.passwordResetData = action.payload;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.passwordResetData = action.payload;
    });
    builder.addCase(fetchUserInformation.fulfilled, (state, action) => {
      state.userInformation = action.payload;
    });
    builder.addCase(fetchUserInformation.rejected, (state, action) => {
      state.userInformation = action.payload;
    });
    builder.addCase(downloadComplaiceReport.fulfilled, (state, action) => {
      state.complainceReportDownload = action.payload;
    });
    builder.addCase(downloadComplaiceReport.rejected, (state, action) => {
      state.complainceReportDownload = action.payload;
    });
    builder.addCase(downloadReports.fulfilled, (state, action) => {
      state.reportsData = action.payload;
    });
    builder.addCase(downloadReports.rejected, (state, action) => {
      state.reportsData = action.payload;
    });
    builder.addCase(getDashboardCardData.fulfilled, (state, action) => {
      state.dashboardCardData = action.payload;
    });
    builder.addCase(getDashboardCardData.rejected, (state, action) => {
      state.dashboardCardData = action.payload;
    });
    builder.addCase(getDashboardChartData.fulfilled, (state, action) => {
      state.dashboardChartData = action.payload;
    });
    builder.addCase(getDashboardChartData.rejected, (state, action) => {
      state.dashboardChartData = action.payload;
    });
  },
});

export default adminSlice.reducer;
