/* eslint-disable no-undef */
import { decryptData } from "./encrypt";

export const Decrypt = () => {
  let decryptedUserData;
  let accessToken;
  let refreshToken;
  let userId;
  let userName;
  let userEmail;
  let profileImage;
  let userStatus;
  let userRole;

  const encryptedsessionStorageData = sessionStorage.getItem("Ukqt");
  const salt = import.meta.env.VITE_SALT;

  if (encryptedsessionStorageData) {
    decryptedUserData = decryptData(encryptedsessionStorageData, salt);
  }

  if (decryptedUserData) {
    accessToken = decryptedUserData?.accessToken;
    refreshToken = decryptedUserData?.refreshToken;
    userId = decryptedUserData?.userId;
    userName = decryptedUserData?.userName;
    userEmail = decryptedUserData?.userEmail;
    profileImage = decryptedUserData?.profileImage;
    userRole = decryptedUserData?.userRole;
    userStatus = decryptedUserData?.status;
  }

  return {
    accessToken,
    refreshToken,
    userId,
    userName,
    userEmail,
    profileImage,
    userRole,
    userStatus,
  };
};
