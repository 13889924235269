/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter, Typography } from "@material-tailwind/react";

import { StatisticsCard } from "../widgets/cards";
import { StatisticsChart } from "../widgets/charts";
import { ClockIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { enableLoading } from "../../redux/slices/loadSlice";
import { getDashboardCardData, getDashboardChartData } from "../../redux/slices/adminSlice";
import { setSnackBar } from "../../redux/slices/snackBarSlice";
import { Decrypt } from "../../components/utils/decrypt";
import { chartsConfig } from "../../configs/charts-config";
import ReactApexChart from "react-apexcharts";
import TabularCard from "./tabulerCards";

export function Home() {
  const [cardsData, setCardsData] = useState({});
  const [solnRunChartData, setSolnRunChartData] = useState({});
  const [emotionsMonthlyChartData, setEmotionsMonthlyChartData] = useState({});
  const [complianceMonthlyChartData, setComplianceMonthlyChartData] = useState({});
  const [happinessIndexChartData, sethappinessIndexChartData] = useState({});
  const [top5SolutionChartData, settop5SolutionChartData] = useState({});
  const [solutionAnalysisChartData, setsolutionAnalysisChartData] = useState({});
  const [complianceAnalysisChartData, setComplianceAnalysisChartData] = useState({});
  const [predectiveHealthChartData, setPredectiveHealthChartData] = useState({});
  const { userRole } = Decrypt();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(enableLoading(true));
    //fetching cards Data
    dispatch(getDashboardCardData())
      .unwrap()
      .then(({ dashboardCardData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardCardData", dashboardCardData);
        setCardsData(dashboardCardData);
      })
      .catch(({ dashboardCardData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardCardData?.message ? dashboardCardData?.message : "Something Went Wrong!",
          })
        );
      });

    //chart data for solution run
    dispatch(getDashboardChartData({ type: "solution_run" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardChartData", dashboardChartData);
        setSolnRunChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
    //chart data for top 5 / 3 solution
    dispatch(getDashboardChartData({ type: "top_solutions" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardChartData", dashboardChartData);
        settop5SolutionChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
    //chart data for solution analysis pie chart
    // dispatch(getDashboardChartData({ type: "solution_analysis" }))
    //   .unwrap()
    //   .then(({ dashboardChartData }) => {
    //     dispatch(enableLoading(false));
    //     // console.log("dashboardChartData", dashboardChartData);
    //     setsolutionAnalysisChartData(dashboardChartData);
    //   })
    //   .catch(({ dashboardChartData }) => {
    //     dispatch(enableLoading(false));
    //     dispatch(
    //       setSnackBar({
    //         isOpen: true,
    //         severity: "error",
    //         msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
    //       })
    //     );
    //   });
    //chart data for complaince_analysis pie chart
    dispatch(getDashboardChartData({ type: "complaince_analysis" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardChartData", dashboardChartData);
        setComplianceAnalysisChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
    //chart data for predective health pie chart
    dispatch(getDashboardChartData({ type: "predective_health" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardChartData", dashboardChartData);
        setPredectiveHealthChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
    //chart data for emotions_monthly area chart
    dispatch(getDashboardChartData({ type: "emotions_monthly" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("setEmotionsMonthlyChartData", dashboardChartData);
        setEmotionsMonthlyChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
    //chart data for compliance_monthly area chart
    dispatch(getDashboardChartData({ type: "compliance_monthly" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("setComplianceMonthlyChartData", dashboardChartData);
        setComplianceMonthlyChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });

    //chart data for happiness index

    dispatch(getDashboardChartData({ type: "happiness_index" }))
      .unwrap()
      .then(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        // console.log("dashboardChartData", dashboardChartData);
        sethappinessIndexChartData(dashboardChartData);
      })
      .catch(({ dashboardChartData }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: dashboardChartData?.message ? dashboardChartData?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch]);

  const statisticsCardsData = [
    {
      title: "Incident Reduction",
      value: cardsData.incident_reduction ? Math.round(cardsData.incident_reduction) + "%" : 0,
      cardIcon: "bxs:down-arrow",
      cardIconColor: "red",
    },
  ];

  if (userRole === "super_admin") {
    statisticsCardsData.push({
      title: "Customers",
      value: cardsData.customers ? cardsData.customers : 0,
      path: "/dashboard/customer",
      cardIcon: "solar:user-circle-bold",
      cardIconColor: "#1e88e5",
    });
  }
  statisticsCardsData.push(
    {
      title: "User Happiness Index",
      value: cardsData.user_happiness_index ? Math.round(cardsData.user_happiness_index) + "%" : 0,
      path: "/dashboard/feedbacks",
      cardIcon: "bxs:up-arrow",
      cardIconColor: "green",
    },
    {
      title: "System Health Report",
      value: cardsData.system_health_report,
      path: "/dashboard/emotional-status",
    },

    {
      title: `Solution Run Total : ${cardsData.solution_run ? cardsData.solution_run : 0}`,
      value: { autofix: cardsData.resolution_using_auto_fix, KB: cardsData.resolution_using_kb, tickets: cardsData.tickets },
      path: "/dashboard/solution-run",
      // cardIcon: "fluent:beaker-settings-16-filled",
      // cardIconColor: "#1e88e5",
    },
    {
      title: "Compliance Report",
      value: cardsData.complaince_data,
      path: "/dashboard/compliance-hardening",
    }

    // {
    //   title: "Resolution(Autofix)",
    //   value: cardsData.resolution_using_auto_fix ? cardsData.resolution_using_auto_fix : 0,
    //   cardIcon: "codicon:lightbulb-autofix",
    //   cardIconColor: "#1e88e5",
    // },
    // {
    //   title: "Resolution(KB)",
    //   value: cardsData.resolution_using_kb ? cardsData.resolution_using_kb : 0,
    //   cardIcon: "lets-icons:book-check-fill",
    //   cardIconColor: "#1e88e5",
    // },
    // {
    //   title: "Tickets",
    //   value: cardsData.tickets ? cardsData.tickets : 0,
    //   path: "/dashboard/tickets",
    //   cardIcon: "mdi:sql-query",
    //   cardIconColor: "#1e88e5",
    // }
  );
  if (userRole === "customer") {
    statisticsCardsData.push({
      title: `Licence Total : ${cardsData.license?.total ? cardsData.license?.total : 0}`,
      value: cardsData.license,
    });
  }
  // charts config
  const solutionRunChart = {
    type: "area",
    height: 300,

    series: [
      {
        name: "Solution",
        data: solnRunChartData.count,
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#d5e3e1"], // colors should be an array
      stroke: {
        curve: "stepline",
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: solnRunChartData.categories,
      },
      // Remove bar-specific plotOptions as they are not applicable to area charts
    },
  };

  const top5SolutionChart = {
    type: "area",
    height: 300,
    series: [
      {
        name: "Solution",
        data: top5SolutionChartData.count,
      },
    ],

    options: {
      ...chartsConfig,
      colors: ["#7af0dc"],
      stroke: {
        curve: "monotoneCubic",
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: top5SolutionChartData.categories,
        labels: {
          style: {
            colors: "#37474f",
            fontSize: "9px",
            fontFamily: "inherit",
            fontWeight: 500,
          },
        },
      },
    },
  };
  const happinessIndexChart = {
    type: "area",
    height: 300,
    series: [
      {
        name: "User",
        data: happinessIndexChartData.count,
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#0288d1"],

      stroke: {
        curve: "straight",
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: happinessIndexChartData.categories,
      },
    },
  };

  const monthlyEmotionsChart = {
    type: "area",
    height: 300,

    series: [
      {
        name: "Happy",
        data: emotionsMonthlyChartData?.count?.count_for_happy,
      },
      {
        name: "Sad",
        data: emotionsMonthlyChartData?.count?.count_for_sad,
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#62f075", "#f0628d"],
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: emotionsMonthlyChartData.categories,
      },
    },
  };
  const monthlyComplianceChart = {
    type: "area",
    height: 300,

    series: [
      {
        name: "Compliant",
        data: complianceMonthlyChartData?.count?.count_for_compliant,
      },
      {
        name: "Non-Compliant",
        data: complianceMonthlyChartData?.count?.count_for_non_compliant,
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#6ef08c", "#f2564b"],
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: complianceMonthlyChartData.categories,
      },
    },
  };

  //create card for charts
  const statisticsChartsData = [
    {
      color: "white",
      title: "Emotions Monthly",
      description: "Emotions Monthly Graph.",
      chart: monthlyEmotionsChart,
    },
    {
      color: "white",
      title: "Compliance Monthly",
      description: "Compliance Monthly Graph.",
      chart: monthlyComplianceChart,
    },
    {
      color: "white",
      title: "Solution Run",
      description: "Total Solution Run In Current Month.",
      chart: solutionRunChart,
    },
    {
      color: "white",
      title: "User Happiness Index",
      description: "User Happiness Index Graph.",
      chart: happinessIndexChart,
    },
    {
      color: "white",
      title: userRole === "super_admin" ? "Top 5 Solution Run" : "Top 3 Solution Run",
      description: userRole === "super_admin" ? "Top 5 Solution Run." : "Top 3 Solution Run.",
      chart: top5SolutionChart,
    },
  ];

  // const solutionAnalysisChart = {
  //   series: solutionAnalysisChartData?.count ? solutionAnalysisChartData?.count : [],
  //   options: {
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     labels: solutionAnalysisChartData?.categories,
  //     responsive: [
  //       {
  //         breakpoint: 1320,
  //         options: {
  //           chart: {
  //             width: 350,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //     colors: ["#44c762", "#2E93fA", "#af54e8"],
  //   },
  // };
  const predectiveHealthChart = {
    series: predectiveHealthChartData?.count ? predectiveHealthChartData?.count : [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: predectiveHealthChartData?.categories,
      responsive: [
        {
          breakpoint: 1320,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#44c762", "#ff0000"],
    },
  };
  const complianceAnalysisChart = {
    series: complianceAnalysisChartData?.count ? complianceAnalysisChartData?.count : [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: complianceAnalysisChartData?.categories,
      responsive: [
        {
          breakpoint: 1320,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#44c762", "#ff0000"],
    },
  };
  return (
    <div className='mt-12'>
      <div className='mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4'>
        {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
          <StatisticsCard key={title} {...rest} title={title} />
        ))}
      </div>
      <TabularCard />

      <div className='mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-1'>
        {statisticsChartsData.map((props) => (
          <StatisticsChart key={props.title} {...props} />
        ))}
      </div>
      <div className='mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-2'>
        <Card className='border border-blue-gray-100 shadow-sm' style={{ justifyContent: "cneter", alignItems: "center" }}>
          <CardBody className='pt-6'>
            <ReactApexChart options={complianceAnalysisChart?.options} series={complianceAnalysisChart?.series} type='donut' width={460} />
            <Typography variant='h6' color='blue-gray' className='text-center pt-6'>
              {"Compliance Analysis"}
            </Typography>
            <Typography variant='small' className='text-center font-normal text-blue-gray-600 pb-6'>
              {"Compliance Analysis Pie Chart."}
            </Typography>
          </CardBody>
        </Card>
        <Card className='border border-blue-gray-100 shadow-sm' style={{ justifyContent: "cneter", alignItems: "center" }}>
          <CardBody className='pt-6'>
            <ReactApexChart options={predectiveHealthChart?.options} series={predectiveHealthChart?.series} type='donut' width={460} />
            <Typography variant='h6' color='blue-gray' className='text-center pt-6'>
              {"Emotions"}
            </Typography>
            <Typography variant='small' className='text-center font-normal text-blue-gray-600 pb-6'>
              {"Emotions Pie Chart."}
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Home;
{
  /* <Card className='border border-blue-gray-100 shadow-sm' style={{ justifyContent: "cneter", alignItems: "center" }}>
          <CardBody className='pt-6'>
            <ReactApexChart options={solutionAnalysisChart?.options} series={solutionAnalysisChart?.series} type='donut' width={460} />
            <Typography variant='h6' color='blue-gray' className='text-center pt-6'>
              {"Solution Analysis"}
            </Typography>
            <Typography variant='small' className='text-center font-normal text-blue-gray-600 pb-6'>
              {"Solution Analysis Pie Chart."}
            </Typography>
          </CardBody>
        </Card> */
}
