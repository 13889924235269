import InfraGridToolbar from "../../../components/DataGridTools/GridToolbar";
import NoRowsOverlay from "../../../components/DataGridTools/NoRowsOverlay";
import { Card, CardHeader, CardBody, Typography, Avatar, Chip, Progress } from "@material-tailwind/react";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enableLoading } from "../../../redux/slices/loadSlice";
import { getDetailedComplainceList } from "../../../redux/slices/listSlice";
import moment from "moment";
import { setSnackBar } from "../../../redux/slices/snackBarSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify";
import { LoadingButton } from "@mui/lab";
import { downloadComplaiceReport } from "../../../redux/slices/adminSlice";
import useResponsive from "../../../components/hooks/useResponsive";

const DetailedComplianceHardning = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log(state);
  const dispatch = useDispatch();
  const [gridRows, setGridRows] = useState([]);
  const isDesktop = useResponsive("up", "lg");

  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    dispatch(enableLoading(true));
    dispatch(getDetailedComplainceList({ id: state?.id }))
      .unwrap()
      .then(({ complainceDetailedList }) => {
        dispatch(enableLoading(false));
        let newMappedArray = complainceDetailedList.results.map((row, i) => ({
          serialNo: i + 1,
          date_moment: moment(row.date).format("DD/MM/YY hh:mm:ss A"),
          ...row,
        }));
        setGridRows(newMappedArray);
        setRowCount(complainceDetailedList.total_count);
      })
      .catch(({ complainceDetailedList }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: complainceDetailedList?.message ? complainceDetailedList?.message : "Something Went Wrong!",
          })
        );
      });
  }, [dispatch, state]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "complaint",
      headerName: "Compliant",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "non_complaint",
      headerName: "Non Compliant",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "run_time",
      headerName: "Run Time",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Chip
          variant='gradient'
          color={row.status === true ? "green" : "red"}
          value={row.status === true ? "Compliant" : "Non-Compliant"}
          className='py-0.5 px-2 text-[11px] font-medium w-fit'
        />
      ),
    },
    {
      field: "action",
      headerName: "Download",
      // headerAlign: "center",
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return (
          <Stack sx={{ width: "100%" }} direction='row' columnGap={1}>
            <Tooltip title='Download'>
              <IconButton size='large' color='inherit' onClick={() => handleDownloadReport(row)}>
                <Iconify icon={"material-symbols-light:cloud-download"} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const handleDownloadReport = (row) => {
    dispatch(enableLoading(true));
    dispatch(downloadComplaiceReport({ id: row?.id }))
      .unwrap()
      .then(({ complainceReportDownload }) => {
        // console.log("complainceReportDownload", complainceReportDownload);
        const file = new Blob([complainceReportDownload], {
          type: "data:text/csv;charset=utf-8",
        });
        const objUrl = window.URL.createObjectURL(file);
        const anchorElement = document.createElement("a");
        anchorElement.href = objUrl;
        anchorElement.download = `${state?.hostname}_${row.date}.csv`;
        anchorElement.click();
        anchorElement.remove();
        window.URL.revokeObjectURL(objUrl);
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "success",
            msg: complainceReportDownload?.message ? complainceReportDownload?.message : "Downloaded Successfully!",
          })
        );
      })
      .catch(({ complainceReportDownload }) => {
        dispatch(enableLoading(false));
        dispatch(
          setSnackBar({
            isOpen: true,
            severity: "error",
            msg: complainceReportDownload?.message ? complainceReportDownload?.message : "Something went wrong!",
          })
        );
      });
  };
  return (
    <div className={isDesktop ? "mt-12 mb-8 flex flex-col gap-12" : "mt-12 mb-8 flex flex-row"}>
      <Card>
        <CardHeader color='blue' className='p-6 flex  items-center'>
          <Iconify
            icon='eva:arrow-back-outline'
            onClick={() => navigate("/dashboard/compliance-hardening", { replace: true })}
            sx={{
              cursor: "pointer",
              marginRight: "1rem",
            }}
          />
          <Typography variant='h6' className='text-white'>
            {state?.hostname}
          </Typography>
        </CardHeader>
        <CardBody className='px-0 pb-0'>
          <Box
            className='w-full min-w-[640px] table-auto'
            sx={{
              display: "flex",
              height: "700px",
              "& .super-app-theme--header": {
                backgroundColor: "#00abc5",
              },
            }}
          >
            <DataGrid
              height={800}
              columns={columns}
              rows={gridRows}
              // rowCount={rowCount}
              getRowId={(row) => row.id}
              disableSelectionOnClick
              scrollbarSize={15}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: InfraGridToolbar,
                // pagination: (props) => (filteredRowCount > 0 ? null : <GridPagination {...props} />),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              // slotProps={{
              //   toolbar: { exportData: exportData },
              // }}
              // pageSizeOptions={[10, 30, 50, 70, 100]}
              pageSizeOptions={[10, 30, 50, 70, 100]}
              disableColumnMenu
              onPaginationModelChange={(newPaginationModel) => {
                // console.log("newPaginationModel", newPaginationModel.page + 1);
                // setState({
                //   ...state,
                //   page: newPaginationModel.page + 1,
                //   pageSize: newPaginationModel.pageSize,
                // });
              }}
              // paginationMode='server'
              // page={page}
              // pageSize={pageSize}
            />
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailedComplianceHardning;
